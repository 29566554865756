import React from 'react'
import { Redirect } from'react-router-dom';


/**
 * 
 * @returns the about page, to be deleted so goes directly to the Scottie google site
 */
class About extends React.Component {
    render() {
        window.open('https://projects.vrac.iastate.edu/scottie/', '', 'noopener,noreferrer');
        return (<Redirect to="/" />); //render function should return something
    };
}

export default About;