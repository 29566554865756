import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Button } from "@material-ui/core"
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';
import { TextareaAutosize } from '@mui/material';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
        width: "100%",
    },
    answerWordsBold: {
        // fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    },
    plusSign: {
        color: "green",
        fontSize: "40px",
    },
    crossSign: {
        color: "red",
        fontSize: "40px",
    },
}));

/**
 * @returns all the rendered questions and answers + dividers
 */
function DemographicsQuestions() {
    //text styles call
    const { questionWords, answerWords, answerWordsBold, divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var initialState = {
        q65: ("q65" in answers["demographics"]) ? answers["demographics"]["q65"] : null, // How many business trips do you make yearly?
        q66: ("q66" in answers["demographics"]) ? answers["demographics"]["q66"] : null, // Please select your gender:
        q66b4: ("q66b4" in answers["demographics"]) ? answers["demographics"]["q66b4"] : "",
        q67: ("q67" in answers["demographics"]) ? answers["demographics"]["q67"] : null, // What is your role in the workplace?
    }
    // const [state, setState] = React.useState({
    //     q65: null, // How many business trips do you make yearly?
    //     q66: null, q66b4: "", // Please select your gender:
    //     q67: null, // What is your role in the workplace?
    // });
    const [state, setState] = React.useState(initialState);

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: "demographics", questionId: k, answer: state[k]}))
        ));
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "demographics", questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
    };

    //handles question box toggle changes
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        dispatch(setAnswer({section: "demographics", questionId: event.target.name, answer: event.target.checked}));
        // console.log(event.target.name);
        // console.log(event.target.checked);
    };

    //handles question text area blurs
    const handleBlur = (event) => {
        setState({ ...state, [event.target.id]: event.target.value });
        dispatch(setAnswer({section: "demographics", questionId: event.target.name, answer: event.target.checked}));
        // console.log(event.target.name);
        // console.log(event.target.checked);
    };

    //sets the values for the select multiple question
    // const { 
    //     q65,
    // } = state;

    const dispatch = useDispatch()

    return(
        <Root>
        <div>
            {/* Demographics */}
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How many business trips do you make yearly?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q65" value={state.q65}>
                        <FormControlLabel
                            value="q65a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Less than once a year</Typography>}
                        />
                        <FormControlLabel
                            value="q65a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Less than once a month</Typography>}
                        />
                        <FormControlLabel
                            value="q65a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>More than twice a month</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            {/* <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">Please select your gender:</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q66" value={state.q66}>
                        <FormControlLabel
                            value="q66a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Woman</Typography>}
                        />
                        <FormControlLabel
                            value="q66a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Man</Typography>}
                        />
                        <FormControlLabel
                            value="q66a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Non-binary</Typography>}
                        />
                        <FormControlLabel
                            value="q66a4" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Something Else</Typography>}
                        />
                        <TextField aria-labelledby="demo-text-field-group-label" id="q66b4" value={state.q66b4} variant="standard" onBlur={handleBlur}></TextField>
                        <FormControlLabel
                            value="q66a5" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Prefer not to say</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider> */}
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">What is your level of professional experience?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q67" value={state.q67}>
                        <FormControlLabel
                            value="q67a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>0-5 years</Typography>}
                        />
                        <FormControlLabel
                            value="q67a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>5-10 years</Typography>}
                        />
                        <FormControlLabel
                            value="q67a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>10-15 years</Typography>}
                        />
                        <FormControlLabel
                            value="q67a4" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>15+ years</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <br></br><br></br>
            {/* <Link to="/questions-general_followups"><Button className={submitButton}>
                Back
            </Button></Link> */}
            <Link to="/questions-meeting_profile"><Button className={submitButton} style={{float: 'right'}}>
                Next
            </Button></Link>
        </div>
        </Root>
    );
}

export default DemographicsQuestions;