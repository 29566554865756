import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Button } from "@material-ui/core"
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';
import { setScores } from '../../features/scoresSlice';
import { TextareaAutosize } from '@mui/material';

import {createResponsesRecord} from "../../database";
import {renderRecommendation} from '../../util';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
        width: "100%",
    },
    answerWordsBold: {
        // fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    },
    plusSign: {
        color: "green",
        fontSize: "40px",
    },
    crossSign: {
        color: "red",
        fontSize: "40px",
    },
}));

var f2fComponent, telComponent, vrComponent, disagreeComponent1, disagreeComponent2, agreeComponent;
function showFollowupAdditionalQuestions(scores, state, classes, questionWords, answerWords, answerWordsBold, handleRadioChange, handleBlur) {
    f2fComponent = telComponent = vrComponent = disagreeComponent1 = disagreeComponent2 = agreeComponent = "";
    var hasNext = false;
    var recSection = scores.final.rec.section;
    var disagree = true;

    if (recSection === "Face-to-Face") {
    // if (true) {
        f2fComponent = 
            // <div className={classes.root}>
            //     <FormControl component="fieldset" className={classes.formControl}>
            //         <FormLabel className={answerWordsBold} component="legend">Your recommendation is to hold a Face-to-Face meeting.</FormLabel>
            //         <br></br>
            //         <FormLabel className={questionWords} component="legend">Do you agree with the meeting recommendation you received?</FormLabel>
            //         <br></br>
            //         <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q47">
            //             <FormControlLabel
            //                 value="q47a1" control={<Radio onChange={handleRadioChange}/>}
            //                 label={<Typography className={answerWords}>No</Typography>}
            //             />
            //             <FormControlLabel
            //                 value="q47a2" control={<Radio onChange={handleRadioChange}/>}
            //                 label={<Typography className={answerWords}>Yes</Typography>}
            //             />
            //         </RadioGroup>
            //     </FormControl>
            // </div>;
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={answerWordsBold} component="legend">Your recommendation is to hold a Face-to-Face meeting.</FormLabel>
                    <br></br>
                    <FormLabel className={questionWords} component="legend">How do you plan to attend this meeting?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q47" value={state.q47}>
                        <FormControlLabel
                            value="q47a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Remote</Typography>}
                        />
                        <FormControlLabel
                            value="q47a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Face-to-face</Typography>}
                        />
                        <FormControlLabel
                            value="q47a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Unsure</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>;

        // disagree = state.q47 === "q47a1";
        disagree = state.q47 !== "q47a2";
    } else if (recSection === "Teleconference") {
    // if (true) {
        telComponent = 
            // <div className={classes.root}>
            //     <FormControl component="fieldset" className={classes.formControl}>
            //         <FormLabel className={answerWordsBold} component="legend">Your recommendation is to hold the meeting via teleconferencing.</FormLabel>
            //         <br></br>
            //         <FormLabel className={questionWords} component="legend">Do you agree with the meeting recommendation you received?</FormLabel>
            //         <br></br>
            //         <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q61">
            //             <FormControlLabel
            //                 value="q61a1" control={<Radio onChange={handleRadioChange}/>}
            //                 label={<Typography className={answerWords}>No</Typography>}
            //             />
            //             <FormControlLabel
            //                 value="q61a2" control={<Radio onChange={handleRadioChange}/>}
            //                 label={<Typography className={answerWords}>Yes</Typography>}
            //             />
            //         </RadioGroup>
            //     </FormControl>
            // </div>;
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={answerWordsBold} component="legend">Your recommendation is to hold the meeting via teleconferencing.</FormLabel>
                    <br></br>
                    <FormLabel className={questionWords} component="legend">How do you plan to attend this meeting?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q47" value={state.q47}>
                        <FormControlLabel
                            value="q47a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Remote</Typography>}
                        />
                        <FormControlLabel
                            value="q47a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Face-to-face</Typography>}
                        />
                        <FormControlLabel
                            value="q47a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Unsure</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>;

        // disagree = state.q61 === "q61a1";
        disagree = state.q47 !== "q47a1";
    } else {
    // if (true) {
        vrComponent = 
            // <div className={classes.root}>
            //     <FormControl component="fieldset" className={classes.formControl}>
            //         <FormLabel className={answerWordsBold} component="legend">Your recommendation is to hold an AR/VR meeting.</FormLabel>
            //         <br></br>
            //         <FormLabel className={questionWords} component="legend">Do you agree with the meeting recommendation you received?</FormLabel>
            //         <br></br>
            //         <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q62">
            //             <FormControlLabel
            //                 value="q62a1" control={<Radio onChange={handleRadioChange}/>}
            //                 label={<Typography className={answerWords}>No</Typography>}
            //             />
            //             <FormControlLabel
            //                 value="q62a2" control={<Radio onChange={handleRadioChange}/>}
            //                 label={<Typography className={answerWords}>Yes</Typography>}
            //             />
            //         </RadioGroup>
            //     </FormControl>
            // </div>;
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={answerWordsBold} component="legend">Your recommendation is to hold an AR/VR meeting.</FormLabel>
                    <br></br>
                    <FormLabel className={questionWords} component="legend">How do you plan to attend this meeting?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q47" value={state.q47}>
                        <FormControlLabel
                            value="q47a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Remote</Typography>}
                        />
                        <FormControlLabel
                            value="q47a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Face-to-face</Typography>}
                        />
                        <FormControlLabel
                            value="q47a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Unsure</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>;

        // disagree = state.q62 === "q62a1";
        disagree = state.q47 !== "q47a1";
    }

    if (disagree) {
        disagreeComponent1 = 
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">What meeting type would you choose instead?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q56" value={state.q56}>
                        <FormControlLabel
                            value="q56a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>AR/VR</Typography>}
                        />
                        <FormControlLabel
                            value="q56a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Teleconferencing</Typography>}
                        />
                        <FormControlLabel
                            value="q56a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Face-to-Face</Typography>}
                        />
                        <FormControlLabel
                            value="q56a4" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Something Else (please describe)</Typography>}
                        />
                        <TextField aria-labelledby="demo-text-field-group-label" id="q56b" inputProps={{ maxLength: 100 }} variant="standard"></TextField>
                    </RadioGroup>
                </FormControl>
            </div>;
        disagreeComponent2 = 
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl} style={{ width: "100%" }}>
                    <FormLabel className={questionWords} component="legend">Why do you disagree?</FormLabel>
                    <br></br>
                    <TextareaAutosize aria-labelledby="demo-text-field-group-label" id="q46" value={state.q46} maxLength={200} variant="standard" minRows={4} className={answerWords} onBlur={handleBlur}></TextareaAutosize>
                </FormControl>
            </div>;
    } else {
        // agreeComponent = 
        //     <div className={classes.root}>
        //         <FormControl component="fieldset" className={classes.formControl} style={{ width: "100%" }}>
        //             <FormLabel className={questionWords} component="legend">Why do you agree?</FormLabel>
        //             <br></br>
        //             <TextareaAutosize aria-labelledby="demo-text-field-group-label" id="q53" value={state.q53} variant="standard" minRows={4} className={answerWords} onBlur={handleBlur}></TextareaAutosize>
        //         </FormControl>
        //     </div>;
    }
}

/**
 * @returns all the rendered questions and answers + dividers
 */
function GeneralFollowupsQuestions() {
    //text styles call
    const { questionWords, answerWords, answerWordsBold, divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var answerName = "general";
    var initialState = {
        q47: ("q47" in answers[answerName]) ? answers[answerName]["q47"] : null, // How do you plan to attend this meeting?
        q56: ("q56" in answers[answerName]) ? answers[answerName]["q56"] : null, // What meeting type would you choose instead?
        q56b: ("q56b" in answers[answerName]) ? answers[answerName]["q56b"] : "", // Something Else (please describe)
        q46: ("q46" in answers[answerName]) ? answers[answerName]["q46"] : null, // Why do you disagree?
        q48a1: ("q48a1" in answers[answerName]) ? answers[answerName]["q48a1"] : false, // Why do you travel for business (select all that apply)?
        q48a2: ("q48a2" in answers[answerName]) ? answers[answerName]["q48a2"] : false, // Why do you travel for business (select all that apply)?
        q48a3: ("q48a3" in answers[answerName]) ? answers[answerName]["q48a3"] : false, // Why do you travel for business (select all that apply)?
        q48a4: ("q48a4" in answers[answerName]) ? answers[answerName]["q48a4"] : false, // Why do you travel for business (select all that apply)?
        q48a5: ("q48a5" in answers[answerName]) ? answers[answerName]["q48a5"] : false, // Why do you travel for business (select all that apply)?
        q48a6: ("q48a6" in answers[answerName]) ? answers[answerName]["q48a6"] : false, // Why do you travel for business (select all that apply)?
        q48a7: ("q48a7" in answers[answerName]) ? answers[answerName]["q48a7"] : false, // Why do you travel for business (select all that apply)?
        q48a7b: ("q48a7b" in answers[answerName]) ? answers[answerName]["q48a7b"] : "", // Why do you travel for business (select all that apply)?
    }
    // const [state, setState] = React.useState({
    //     // q47: null, // Do you agree with the meeting recommendation you received?
    //     // q61: null, // Do you agree with the meeting recommendation you received?
    //     // q62: null, // Do you agree with the meeting recommendation you received?
    //     q47: null, // How do you plan to attend this meeting?
    //     q56: null, // What meeting type would you choose instead?
    //     q56b: "", // Something Else (please describe)
    //     q46: null, // Why do you disagree?
    //     // q53: null, // Why do you agree?
    //     q48a1: false, q48a2: false, q48a3: false, q48a4: false, q48a5: false, q48a6: false, q48a7: false, q48a7b: "", // Why do you travel for business (select all that apply)?
    //     // q49: "", // In your own words, why do you travel for business instead of conducting meetings remotely?
    // });
    const [state, setState] = React.useState(initialState);

    const quiz = useSelector((s) => s.quiz);
    const scores = useSelector((s) => s.scores);

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: "general", questionId: k, answer: state[k]}))
        ));
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "general", questionId: event.target.name, answer: event.target.value}));
        if (event.target.name === "q47") {
            var planScore = "Remote";
            if (event.target.value === "q47a2") {
                planScore = "Face-to-Face";
            } else if (event.target.value === "q47a3") {
                planScore = "Unsure";
            }
            dispatch(setScores({how: "final", section: "plan", questionId: "section", score: planScore}));
        }
        // console.log(event.target.name);
        // console.log(event.target.value);
    };

    //handles question box toggle changes
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        dispatch(setAnswer({section: "general", questionId: event.target.name, answer: event.target.checked}));
        // console.log(event.target.name);
        // console.log(event.target.checked);
    };

    //handles question text area blurs
    const handleBlur = (event) => {
        setState({ ...state, [event.target.id]: event.target.value });
        dispatch(setAnswer({section: "general", questionId: event.target.id, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.checked);
    };

    //sets the values for the select multiple question
    // const { 
    //     q48a1, q48a2, q48a3, q48a4, q48a5, q48a6, q48a7,
    // } = state;

    const dispatch = useDispatch();

    const submitResults = (event) => {
        let convertedQuiz = {...quiz};
        if(true) {
            for (const property in quiz) {
                convertedQuiz[property] = renderRecommendation(quiz[property]);
              }
        }

        try {
            createResponsesRecord(
                convertedQuiz, 
                answers, 
                scores.final.plan.section,
                scores.final.rec.section,
            );
        }
        catch(e) {
            console.log(e);
        }
    }

    const updateTravelReason = (event) => {
        setState({ ...state, ["q48a7b"]: event.target.value });
        dispatch(setAnswer({section: "general", questionId: "q48a7b", answer: event.target.value}));
    };

    return(
        <Root>
        <div>
            {/* General Follow-ups */}
            {showFollowupAdditionalQuestions(scores, state, classes, questionWords, answerWords, answerWordsBold, handleRadioChange, handleBlur)}
            {f2fComponent}
            {telComponent}
            {vrComponent}
            <Divider textAlign="right" className={divWords}></Divider>
            {/* {((state.q47 !== null) || (state.q61 !== null) || (state.q62 !== null)) ? disagreeComponent1 : ""} */}
            {/* {((state.q47 !== null) || (state.q61 !== null) || (state.q62 !== null)) ? disagreeComponent2 : ""} */}
            {/* {((state.q47 !== null) || (state.q61 !== null) || (state.q62 !== null)) ? agreeComponent : ""} */}
            {(state.q47 !== null) ? disagreeComponent2 : ""}
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">Why do you travel for business (select all that apply)?</FormLabel>
                    <br></br>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a1} onChange={handleChange} name="q48a1" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>My supervisor requires it</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a2} onChange={handleChange} name="q48a2" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>I want to get out of the office</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a3} onChange={handleChange} name="q48a3" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>I like to see colleagues in person</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a4} onChange={handleChange} name="q48a4" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Meetings are easier in person</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a5} onChange={handleChange} name="q48a5" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>I like to see new places</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a6} onChange={handleChange} name="q48a6" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>The business I conduct cannot be conducted remotely</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q48a7} onChange={handleChange} name="q48a7" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Other</Typography>}
                        />
                        <TextField aria-labelledby="demo-text-field-group-label" id="q48a7b" value={state.q48a7b} inputProps={{ maxLength: 100 }} variant="standard" onChange={updateTravelReason}></TextField>
                    </FormGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            {/* <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">In your own words, why do you travel for business instead of conducting meetings remotely?</FormLabel>
                    <br></br>
                    <TextareaAutosize aria-labelledby="demo-text-field-group-label" id="q49" variant="standard" minRows={4} className={answerWords} onBlur={handleBlur}></TextareaAutosize>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider> */}
            <br></br><br></br>
            {/* <Link to="/questions-confidence_perf"><Button className={submitButton}>
                Back
            </Button></Link> */}
            {/* <Link to="/questions-tool_feedback"><Button className={submitButton} style={{float: 'right'}}>
                Next
            </Button></Link> */}
            <Link to="/end"><Button className={submitButton} style={{float: 'right'}} onClick={submitResults}>
                Submit
            </Button></Link>
        </div>
        </Root>
    );
}

export default GeneralFollowupsQuestions;