import Header from './Header'
import airscottie from './components/airscottie.png'

import { makeStyles, Button } from "@material-ui/core"
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * sets the css styles for the different components
 */
const useStyles = makeStyles(() => ({
    quizButton: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 700,
      fontSize: "20px",
    //   textTransform: "lowercase",
      color: "#FFFFEE",
      backgroundColor: "#1E2124",
    },
    mainWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "40px",
        // textTransform: "lowercase",
        margin: "25px",
        textAlign: "center",
        color: "#1E2124",
    },
    container: {
        marginLeft: 5,
        width: "99%",
        height: 450,
        display: "inline-block",
        marginTop: 100,
    },
    content_container: {
        flex: "center",
        margin: 1,
        height: "98%",
    },
    link: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "17px",
        // textTransform: "lowercase",
        color: "#1E2124",
    },
}));

/**
 * @returns renders the header + image + message + button
 */
function Home() {
    const { container, content_container, mainWords, quizButton, link } = useStyles();

    return(
        <div>
            <Header />
            <div className={container}>
                <div className={content_container} style={{ float: "left", width: "43.4%"}}>
                    <br></br><br></br><br></br>
                    <h2 className={mainWords}>
                        Should you<br></br>
                        travel for<br></br> 
                        your meeting?<br></br>
                    </h2>
                    <br></br>
                    <div style={{textAlign: "center"}}>
                        <Link to="/quiz"><Button variant="contained" className={quizButton}>
                            Take Our Quiz
                        </Button></Link>
                        <br></br><br></br>
                        <Link to="/results" className={link}>or skip directly to our tool</Link>
                    </div>
                    
                </div>
                <div className={content_container} style={{ float: "right", width: "54.3%"}}>
                    <img src={airscottie} alt="airplane" style={{ width: "100%", marginTop: 40,}}/>
                </div>
            </div>
        </div>
    );
}

export default Home;