export const renderRecommendation = (rec) => {
    // if(process.env.REACT_APP_COLLAPSE_VR_AND_TELECONFERENCE.toLowerCase() === "true") {
    if (false) {
        // convert recs to an enum-like structure
        switch(rec) {
            case "Face-to-Face":
                return rec;
            case "Teleconference":
                return "Remote";
            case "Virtual Reality":
                return "Remote";
            case "Face-to-Face or Virtual Reality":
                return "Face-to-Face or Remote";
            case "Virtual Reality or Face-to-Face":
                return "Remote or Face-to-Face"
            default:
                return rec;
        }
    // }
    }
    return rec;
}