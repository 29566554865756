import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Dialog from "@material-ui/core/Dialog";
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Button, Table, TableBody, TableRow, TableCell } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';
import { setScores } from '../../features/scoresSlice';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    }
}));

/**
 * @returns all the rendered questions and answers + dividers
 */
function MeetingProfileQuestions() {
    //text styles call
    const { questionWords, answerWords,divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var initialState = {
        q70: ("q70" in answers["meeting"]) ? answers["meeting"]["q70"] : null, // What is your age?
        q71a: ("q71a" in answers["meeting"]) ? answers["meeting"]["q71a"] : false, // Have you traveled for business within the last year?
        q71b: ("q71b" in answers["meeting"]) ? answers["meeting"]["q71b"] : false, // Have you traveled for business within the last year?
        q55a: ("q55a" in answers["meeting"]) ? answers["meeting"]["q55a"] : "q55a1", // What are the goals of the meeting?
        q55b: ("q55b" in answers["meeting"]) ? answers["meeting"]["q55b"] : "q55b1", // What are the goals of the meeting?
        q55c: ("q55c" in answers["meeting"]) ? answers["meeting"]["q55c"] : "q55c1", // What are the goals of the meeting?
        q55d: ("q55d" in answers["meeting"]) ? answers["meeting"]["q55d"] : "q55d4", // What are the goals of the meeting?
        q55e: ("q55e" in answers["meeting"]) ? answers["meeting"]["q55e"] : "q55e4", // What are the goals of the meeting?
    }
    // const [state, setState] = React.useState({
    //     q70: null, // What is your age?
    //     q71a: false, q71b: false, // Have you traveled for business within the last year?
    //     q55a: "q55a1", q55b: "q55b1", q55c: "q55c1", q55d: "q55d4", q55e: "q55e4", // What are the goals of the meeting?
    //     // q2: null, // How long will it take to accomplish the goals of the meeting?
    // });
    const [state, setState] = React.useState(initialState);
    const [knowledgeIsOpen, setKnowledgeIsOpen] = React.useState(false);

    const [isAgeValid, setIsAgeValid] = React.useState(true);

    const [meetingScores, setMeetingScores] = React.useState({
        tel: {
            q55a: 0, q55b: 0, q55c: 0, q55d: 0, q55e: 0,
            q2: 0,
        },
        vr: {
            q55a: 0, q55b: 0, q55c: 0, q55d: 0, q55e: 0,
            q2: 0,
        },
        f2f: {
            q55a: 0, q55b: 0, q55c: 0, q55d: 0, q55e: 0,
            q2: 0,
        },
    });

    /**
     * Opens the popup of the knowledge construct
     * NOTE: does not open popup when a "degree" button is clicked
     * @param {*} e 
     */
    const openKnowledgeModal = (e) => {
        // state.title = e.target.outerText;
        setKnowledgeIsOpen(true);
    }
    /**
     * Closes the popup 
     */
    const closeKnowledgeModal = () => {
        setKnowledgeIsOpen(false);
    }

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: "meeting", questionId: k, answer: state[k]}))
        ));
        setKnowledge();
        // setMentalWorkload();
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "meeting", questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
        var howScore = {f2f: 0, vr: 0, tel: 0};
        var section = "meeting";
        if (event.target.name === "q55a") {
            if (event.target.value === "q55a2") {
                howScore.tel = 1;
            } else if (event.target.value === "q55a3") {
                howScore.tel = 2;
            } else if (event.target.value === "q55a4") {
                howScore.tel = 3;
            }
        } else if (event.target.name === "q55b") {
            if (event.target.value === "q55b2") {
                howScore.tel = 1;
            } else if (event.target.value === "q55b3") {
                howScore.tel = 2;
            } else if (event.target.value === "q55b4") {
                howScore.tel = 3;
            }
        } else if (event.target.name === "q55c") {
            if (event.target.value === "q55c2") {
                howScore.tel = 1;
            } else if (event.target.value === "q55c3") {
                howScore.tel = 2;
            } else if (event.target.value === "q55c4") {
                howScore.tel = 3;
            }
        } else if (event.target.name === "q55d") {
            if (event.target.value === "q55d2") {
                howScore.f2f = 1;
                howScore.vr = 1;
            } else if (event.target.value === "q55d3") {
                howScore.f2f = 2;
                howScore.vr = 2;
            } else if (event.target.value === "q55d4") {
                howScore.f2f = 3;
                howScore.vr = 3;
            }
        } else if (event.target.name === "q55e") {
            if (event.target.value === "q55e2") {
                howScore.f2f = 1;
                howScore.vr = 1;
            } else if (event.target.value === "q55e3") {
                howScore.f2f = 2;
                howScore.vr = 2;
            } else if (event.target.value === "q55e4") {
                howScore.f2f = 3;
                howScore.vr = 3;
            }
        } else if (event.target.name === "q2") {
            if (event.target.value === "q2a1") {
                howScore.tel = 1;
            } else if (event.target.value === "q2a2") {
                howScore.f2f = 1;
                howScore.vr = 1;
            } else if (event.target.value === "q2a3") {
                howScore.f2f = 1;
                howScore.vr = 1;
            }
        }
        var prevScores = {...meetingScores};
        for (var how in howScore) {
            prevScores[how][event.target.name] = howScore[how];
            setMeetingScores(prevScores);
            dispatch(setScores({how: how, section: section, questionId: event.target.name, score: howScore[how]}));
        }
    };

    // Validates age value
    const validateAge = (event) => {
        parseInt(event.target.value) >= 18 ? setIsAgeValid(true) : setIsAgeValid(false);
    };

    //handles question box toggle changes
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        dispatch(setAnswer({section: "meeting", questionId: event.target.name, answer: event.target.checked}));
        // console.log(event.target.name);
        // console.log(event.target.checked);
    };

    //sets the values for the select multiple question
    const { 
        q71a, q71b, 
    } = state;

    const dispatch = useDispatch()

    //set knowledge slice for results table
    const setKnowledge = () => {
        var newDegree, low;
        low = 0;

        //q1, high and medium take precedence over low
        if (["q55d2", "q55d3", "q55d4"].includes(state.q55d) || 
            ["q55e2", "q55e3", "q55e4"].includes(state.q55e)) {
            newDegree = "Virtual Reality or Face-to-Face";
        }
        else    
            low++;
        
        //set newDegree low and med
        if (low >= 1 && newDegree !== "Virtual Reality or Face-to-Face") 
            newDegree = "Teleconference";

        dispatch(setDegree({section: "knowledge", degree: newDegree}));
        // console.log(newDegree);
    };

    // //set mental_workload slice for results table
    // const setMentalWorkload = () => {
    //     var newDegree, low, high, med;
    //     low = med = high = 0;

    //     if (state.q2 === "q2a3")
    //         low++;
    //     else if (state.q2 === "q2a2")
    //         med++;
    //     else
    //         high++;

    //     //set newDegree low and med
    //     if (low >= med && low >= high) 
    //         newDegree = "Teleconference";
    //     else if (med >= high) 
    //         newDegree = "Virtual Reality";
    //     else 
    //         newDegree = "Face-to-Face"

    //     dispatch(setDegree({section: "mental_workload", degree: newDegree}));
    // };

    return(
        <Root>
        <div>
            {/* Meeting Profile */}
            {/* <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl} name="age">
                    <FormLabel className={questionWords} component="legend">What is your age?</FormLabel>
                    <br></br>
                    <TextField aria-labelledby="demo-text-field-group-label" id="q70" variant="standard" onBlur={validateAge} error={!isAgeValid} helperText={isAgeValid ? "" : "You must be 18 or older to take this survey."}></TextField>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider> */}
            {
                isAgeValid 
                ? 
                <div>
                    <div className={classes.root}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel className={questionWords} component="legend">Have you traveled for business within the last year? OR Will you be traveling for business within the next year? (select all that apply)</FormLabel>
                            <br></br>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={q71a} onChange={handleChange} name="q71a" value={state.q71a} style={{ color: "grey" }} />}
                                    label={<Typography className={answerWords}>Yes, I have traveled for business within the LAST year</Typography>}
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={q71b} onChange={handleChange} name="q71b" value={state.q71b} style={{ color: "grey" }} />}
                                    label={<Typography className={answerWords}>Yes, I will be traveling for business within the NEXT</Typography>}
                                />
                            </FormGroup>
                        </FormControl>
                    </div>
                    <Divider textAlign="right" className={divWords}></Divider>
                    <div className={classes.root}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel className={questionWords} component="legend">In this section, you will be asked questions about attending a business meeting that might require travel. The goal of these questions is to help you decide how to attend the meeting: in person, via teleconferencing (like Zoom), or by using augmented or virtual reality (AR/VR).</FormLabel>
                            <br></br>
                            <FormLabel className={questionWords} component="legend">When answering these questions, think about the next time you will be traveling for business. If you don't have any business travel planned, think about the last business trip you took.</FormLabel>
                        </FormControl>
                    </div>
                    <Divider textAlign="right" className={divWords} onClick={openKnowledgeModal}>Knowledge &#9432;</Divider>
                    <div className={classes.root}>
                        <FormControl className={classes.formControl}>
                            <FormLabel className={questionWords} component="legend">What are the goals of the meeting?</FormLabel>
                            <br></br>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>We won't do this at all</TableCell>
                                        <TableCell>We will do some of this</TableCell>
                                        <TableCell>We will do a lot of this</TableCell>
                                        <TableCell>This is the main purpose of the meeting</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>To be introduced to new people or a new team</TableCell>
                                        <TableCell><Radio name="q55a" checked={state["q55a"] === "q55a1"} value="q55a1" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55a" checked={state["q55a"] === "q55a2"} value="q55a2" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55a" checked={state["q55a"] === "q55a3"} value="q55a3" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55a" checked={state["q55a"] === "q55a4"} value="q55a4" onChange={handleRadioChange}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>To share information</TableCell>
                                        <TableCell><Radio name="q55b" checked={state["q55b"] === "q55b1"} value="q55b1" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55b" checked={state["q55b"] === "q55b2"} value="q55b2" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55b" checked={state["q55b"] === "q55b3"} value="q55b3" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55b" checked={state["q55b"] === "q55b4"} value="q55b4" onChange={handleRadioChange}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>To discuss</TableCell>
                                        <TableCell><Radio name="q55c" checked={state["q55c"] === "q55c1"} value="q55c1" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55c" checked={state["q55c"] === "q55c2"} value="q55c2" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55c" checked={state["q55c"] === "q55c3"} value="q55c3" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55c" checked={state["q55c"] === "q55c4"} value="q55c4" onChange={handleRadioChange}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>To make a decision</TableCell>
                                        <TableCell><Radio name="q55d" checked={state["q55d"] === "q55d1"} value="q55d1" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55d" checked={state["q55d"] === "q55d2"} value="q55d2" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55d" checked={state["q55d"] === "q55d3"} value="q55d3" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55d" checked={state["q55d"] === "q55d4"} value="q55d4" onChange={handleRadioChange}/></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>To teach</TableCell>
                                        <TableCell><Radio name="q55e" checked={state["q55e"] === "q55e1"} value="q55e1" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55e" checked={state["q55e"] === "q55e2"} value="q55e2" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55e" checked={state["q55e"] === "q55e3"} value="q55e3" onChange={handleRadioChange}/></TableCell>
                                        <TableCell><Radio name="q55e" checked={state["q55e"] === "q55e4"} value="q55e4" onChange={handleRadioChange}/></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </FormControl>
                    </div>
                    <Divider textAlign="right" className={divWords}></Divider>
                    {/* <div className={classes.root}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel className={questionWords} component="legend">How long will it take to accomplish the goals of the meeting?</FormLabel>
                            <br></br>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q2">
                                <FormControlLabel
                                    value="q2a1" control={<Radio onChange={handleRadioChange}/>}
                                    label={<Typography className={answerWords}>1-2 hours</Typography>}
                                />
                                <FormControlLabel
                                    value="q2a2" control={<Radio onChange={handleRadioChange}/>}
                                    label={<Typography className={answerWords}>Half a day (4 hours)</Typography>}
                                />
                                <FormControlLabel
                                    value="q2a3" control={<Radio onChange={handleRadioChange}/>}
                                    label={<Typography className={answerWords}>A full day or more</Typography>}
                                />
                            </RadioGroup>
                        </FormControl>
                    </div> */}
                </div>
                :
                ""
            }
        </div>
        <br></br><br></br>
        {/* <Link to="/questions-informed_consent"><Button className={submitButton}>
            Back
        </Button></Link> */}
        {
            (isAgeValid) ?
            <Link to="/questions-mental_workload_ssa"><Button className={submitButton} style={{float: 'right'}}>
                Next
            </Button></Link>
            :
            ""
        }
        {/* knowledge popup */}
        <Dialog open={knowledgeIsOpen} onClose={closeKnowledgeModal} maxWidth='md' fullWidth={true}>
            <DialogTitle margin='10%' align='center'>Knowledge</DialogTitle>
            <DialogContent>
                <DialogContentText margin='10%' align='center'>
                    How well the participant retains learned knowledge about the topic of discussion. 
                </DialogContentText>
            </DialogContent>
            <Button onClick={closeKnowledgeModal}>Close</Button>
        </Dialog>
        </Root>
    );
}

export default MeetingProfileQuestions;