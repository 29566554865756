import Quiz from './pages/Quiz'
import About from './pages/About'
import Home from './pages/Home'
import Results from './pages/Results'
import End from './pages/End'
import { Route } from 'react-router-dom'
import Descriptions from './pages/ConstructDescriptions'
import QuestionsIntroduction from './pages/question pages/QuestionsIntroduction'
import QuestionsMeetingProfile from './pages/question pages/QuestionsMeetingProfile'
// import QuestionsMentalWorkload from './pages/question pages/QuestionsMentalWorkload'
// import QuestionsSSA from './pages/question pages/QuestionsSSA'
import QuestionsMentalWorkloadSSA from './pages/question pages/QuestionsMentalWorkloadSSA'
import QuestionsConfidencePerformance from './pages/question pages/QuestionsConfidencePerformance'
import QuestionsTelFollowups from './pages/question pages/QuestionsTelFollowups'
import QuestionsVRFollowups from './pages/question pages/QuestionsVRFollowups'
import QuestionsF2FFollowups from './pages/question pages/QuestionsF2FFollowups'
import QuestionsGeneralFollowups from './pages/question pages/QuestionsGeneralFollowups'
// import QuestionsToolFeedback from './pages/question pages/QuestionsToolFeedback'
import QuestionsDemographics from './pages/question pages/QuestionsDemographics'

function App() {
  return (
    <div className="App">
      {/* Main Pages */}
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/quiz" component={Quiz} />
      <Route exact path="/results" component={Results} />
      <Route exact path="/descriptions" component={Descriptions} />
      <Route exact path="/end" component={End} />
      {/* quiz question pages */}
      <Route exact path="/questions-intro" component={QuestionsIntroduction} />
      <Route exact path="/questions-demographics" component={QuestionsDemographics} />
      <Route exact path="/questions-meeting_profile" component={QuestionsMeetingProfile} />
      <Route exact path="/questions-mental_workload_ssa" component={QuestionsMentalWorkloadSSA} />
      {/* <Route exact path="/questions-mental_workload" component={QuestionsMentalWorkload} />
      <Route exact path="/questions-ssa" component={QuestionsSSA} /> */}
      <Route exact path="/questions-confidence_perf" component={QuestionsConfidencePerformance} />
      <Route exact path="/questions-tel_followups" component={QuestionsTelFollowups} />
      <Route exact path="/questions-vr_followups" component={QuestionsVRFollowups} />
      <Route exact path="/questions-f2f_followups" component={QuestionsF2FFollowups} />
      <Route exact path="/questions-general_followups" component={QuestionsGeneralFollowups} />
      {/* <Route exact path="/questions-tool_feedback" component={QuestionsToolFeedback} /> */}
    </div>
  );
}
export default App;
