import React from 'react'
import { useDispatch } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Dialog from "@material-ui/core/Dialog";
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'   
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles, Typography, Button } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';
import { textAlign } from '@mui/system';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    }
}));

/**
 * @returns all the rendered questions and answers + dividers
 */
function IntroductionQuestions() {
    //text styles call
    const { questionWords, answerWords,divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const [state, setState] = React.useState({
        q68: null, // Consent answer
    });

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "consent", questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
    };

    const dispatch = useDispatch()

    return(
        <Root>
        <div>
            {/* INFORMED CONSENT DOCUMENT */}
            <div className={classes.divWords}>
                <h1 style={{textAlign: "center"}}>
                    Travel Substitutue Tool
                </h1>
                <p>
                    Should you host your event/meeting in-person or remotely? Do a comparison to see what the cost and emissions savings are to make informed decisions about travel that is good for your organization, and good for the planet.
                </p>
                <p>
                    The recommendations provided are based on a study conducted by Raytheon Technologies and Iowa State University.  For more information, please see <a href='https://projects.vrac.iastate.edu/scottie/'>https://projects.vrac.iastate.edu/scottie/</a>.
                </p>
                <p>
                    The information, data, or work presented herein was funded in part by the Advanced Research Projects Agency-Energy (ARPA-E), U.S. Department of Energy, under Award Number DE-AR0001097. The views and opinions of authors expressed herein do not necessarily state or reflect those of the United States Government or any agency thereof.
                </p>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <br></br><br></br>
            {/* <Link to="/quiz"><Button className={submitButton}>
                Back
            </Button></Link> */}
            <Link to="/questions-demographics"><Button className={submitButton} style={{float: 'right'}}>Next</Button></Link>
        </div>
        </Root>
    );
}

export default IntroductionQuestions;