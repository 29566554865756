import { configureStore } from '@reduxjs/toolkit'
import quizreducer from './features/quizSlice'
import answersreducer from "./features/answersSlice";
import scoresreducer from "./features/scoresSlice";

export const store = configureStore({
  reducer: {
      quiz: quizreducer,
      answers: answersreducer,
      scores: scoresreducer,
  },
})