import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Dialog from "@material-ui/core/Dialog";
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Button, TableBody, TableRow, TableCell } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';
import { setScores } from '../../features/scoresSlice';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    }
}));

/**
 * @returns all the rendered questions and answers + dividers
 */
function MentalWorkloadSSAQuestions() {
    //text styles call
    const { questionWords, answerWords,divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var initialState = {
        q2: ("q2" in answers["mentalwl"]) ? answers["mentalwl"]["q2"] : "q2a3", // How long will it take to accomplish the goals of the meeting?
        q16: ("q16" in answers["mentalwl"]) ? answers["mentalwl"]["q16"] : "q16a3", // How easy is the topic of discussion for the participants?
        q10: ("q10" in answers["ssa"]) ? answers["ssa"]["q10"] : "q10a3", // How important is it for all participants to comprehend the same concepts at the same time?
    }
    // const [state, setState] = React.useState({
    //     q2: "q2a3", // How long will it take to accomplish the goals of the meeting?
    //     q16: "q16a3", // How easy is the topic of discussion for the participants?
    //     q10: "q10a3", // How important is it for all participants to comprehend the same concepts at the same time?
    // });
    const [state, setState] = React.useState(initialState);

    const [mentalWLScores, setMentalWLScores] = React.useState({
        tel: {
            q16: 0,
        },
        vr: {
            q16: 0,
        },
        f2f: {
            q16: 0,
        },
    });

    const [mwIsOpen, setMWIsOpen] = React.useState(false);
    const [SSAIsOpen, setSSAIsOpen] = React.useState(false);

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: (k === "q10") ? "ssa" : "mentalwl", questionId: k, answer: state[k]}))
        ));
        setMentalWorkload();
        setSSA();
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        var section = (event.target.name === "q10") ? "ssa" : "mentalwl";
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: section, questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
        var howScore = {f2f: 0, vr: 0, tel: 0};
        var section = "mentalwl";
        if (event.target.name === "q16") {
            if (event.target.value === "q16a1") {
                howScore.tel = 1;
            } else if (event.target.value === "q16a2") {
                howScore.f2f = 1;
                howScore.vr = 1;
            } else if (event.target.value === "q16a3") {
                howScore.f2f = 1;
                howScore.vr = 1;
            }
        }
        var prevScores = {...mentalWLScores};
        for (var how in howScore) {
            prevScores[how][event.target.name] = howScore[how];
            setMentalWLScores(prevScores);
            dispatch(setScores({how: how, section: section, questionId: event.target.name, score: howScore[how]}));
        }
    };

    /**
     * Opens the popup of the mental workload construct
     * NOTE: does not open popup when a "degree" button is clicked
     * @param {*} e 
     */
    const openMwModal = (e) => {
        setMWIsOpen(true);
    }
    /**
     * Closes the popup 
     */
    const closeMwModal = () => {
        setMWIsOpen(false);
    }

    /**
     * Opens the popup of the corresponding table cell
     * NOTE: does not open popup when a "degree" button is clicked
     * @param {*} e 
     */
    const openSSAModal = (e) => {
        setSSAIsOpen(true);
    }
    /**
     * Closes the popup 
     */
    const closeSSAModal = () => {
        setSSAIsOpen(false);
    }

    //set mental_workload slice for results table
    const setMentalWorkload = () => {
        var newDegree, low, high, med;
        low = med = high = 0;

        if (state.q16 === "q16a1") {
            low++;
        }
        else if (state.q16 === "q16a2")
            med++;
        else
            high++;
        
        if (state.q2 === "q2a1")
            low++;
        else if (state.q2 === "q2a2")
            med++;
        else
            high++;

        //set newDegree low and med
        if (low >= med && low >= high) 
            newDegree = "Teleconference";
        else if (med >= high) 
            newDegree = "Virtual Reality";
        else 
            newDegree = "Face-to-Face"

        dispatch(setDegree({section: "mental_workload", degree: newDegree}));
    };

    /**
     * sets the SSA slice value
     */
    const setSSA = () => {
        var newDegree, low, high, med;
        low = med = high = 0;

        if (state.q10 === "q10a1")
            low++;
        else if (state.q10 === "q10a2")
            med++;
        else
            high++;

        //set newDegree
        if (high >= med && high >= low) //high
            newDegree = "Face-to-Face";
        else if (med >= low) //med
            newDegree = "Virtual Reality";
        else //low
            newDegree = "Teleconference";

        dispatch(setDegree({section: "ssa", degree: newDegree}));
        // console.log(newDegree); //for testing
        // console.log(low);
        // console.log(med);
        // console.log(high);
    };

    const dispatch = useDispatch()

    return(
        <Root>
        <div>
            {/* Mental Workload */}
            <Divider textAlign="right" className={divWords} onClick={openMwModal}>Mental Workload &#9432;</Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How long will it take to accomplish the goals of the meeting?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q2" value={state.q2}>
                        <FormControlLabel
                            value="q2a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>1-2 hours</Typography>}
                        />
                        <FormControlLabel
                            value="q2a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Half a day (4 hours)</Typography>}
                        />
                        <FormControlLabel
                            value="q2a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>A full day or more</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How easy is the topic of discussion for the participants?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q16" value={state.q16}>
                        <FormControlLabel
                            value="q16a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Easy</Typography>}
                        />
                        <FormControlLabel
                            value="q16a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Neither easy nor difficult</Typography>}
                        />
                        <FormControlLabel
                            value="q16a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Difficult</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            {/* Shared Situational Awareness */}
            <Divider textAlign="right" className={divWords} onClick={openSSAModal}>Shared Situational Awareness &#9432;</Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How important is it for all participants to comprehend the same concepts at the same time?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q10" value={state.q10}>
                        <FormControlLabel
                            value="q10a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Low importance</Typography>}
                        />
                        <FormControlLabel
                            value="q10a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Medium importance</Typography>}
                        />
                        <FormControlLabel
                            value="q10a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>High importance</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
        </div>
        {/* mental workload popup */}
        <Dialog open={mwIsOpen} onClose={closeMwModal} maxWidth='md' fullWidth={true}>
            <DialogTitle margin='10%' align='center'>Mental Workload</DialogTitle>
            <DialogContent>
                <DialogContentText margin='10%' align='center'>
                The ease of mental effort that the participants need to expend to carry out the purpose of the meeting without overloading the participant. 
                </DialogContentText>
                </DialogContent>
                <Button onClick={closeMwModal}>Close</Button>
        </Dialog>
        <Dialog open={SSAIsOpen} onClose={closeSSAModal} maxWidth='md' fullWidth={true}>
            <DialogTitle margin='10%' align='center'>Shared Situational Awareness</DialogTitle>
            <DialogContent>
                <DialogContentText margin='10%' align='center'>
                The extent to which participants have a commonly understood mental model of a situation (i.e., what is currently happening and what is going to happen). 
                </DialogContentText>
                </DialogContent>
                <Button onClick={closeSSAModal}>Close</Button>
        </Dialog>
        </Root>
    );
}

export default MentalWorkloadSSAQuestions;