import Header from './Header'
import React  from 'react';
import { makeStyles }from "@material-ui/core";

/**
 * sets the css styles for the different components
 */
 const useStyles = makeStyles(() => ({
    mainWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "40px",
        margin: "25px",
        textAlign: "center",
        color: "#1E2124",
    },
    headers: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        margin: "25px",
        textAlign: "left",
        color: "#1E2124",
    },
    subheaders: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 5900,
        fontSize: "22px",
        margin: "25px",
        textAlign: "left",
        color: "#1E2124",
    },
    contentWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 550,
        fontSize: "20px",
        margin: "25px",
        textAlign: "left",
        color: "#1E2124",
    },
}));
/**
 * 
 * @returns the page that displays all descritpions for all of the different constructs
 */
function Descriptions() {
    const { headers, contentWords, mainWords, subheaders } = useStyles();
    return(
        <div style={{margin: "7%"}}>
            <Header/>
            <h1 className={mainWords} style={{marginTop: '10%'}}>Recommendation Explanations</h1>
                <h3 className={headers}>Receiving 'Face-to-Face Only'</h3>
                    <p className={contentWords}>
                        This Recommendation occurs when a user selects that Meeting Participants wont have access to the necessary
                        AR/VR or Teleconferencing equipment in the Usability: Ease of Use portion of the Quiz. Thus, while we will show 
                        the Recommendations calculated for the other Constructs, the cumulative Recommendation can only be Face-to-Face. 
                    </p>
                <br></br>
                <h3 className={headers}>Receiving 'Face-to-Face, Virtual Reality Impossible' or 'Teleconference, Virtual Reality Impossible'</h3>
                    <p className={contentWords}>
                        This Recommendation occurs when a user selects that Meeting Participants wont have access to the necessary
                        AR/VR equipment in the Usability: Ease of Use portion of the Quiz. Thus, while we will show 
                        the Recommendations calculated for the other Constructs, the cumulative Recommendation can only be Face-to-Face or Teleconference. 
                    </p>
            <br></br>

            <h1 className={mainWords}>Construct Descriptions</h1>
            
                <h3 className={headers}>Knowledge</h3>
                    <p className={contentWords}>Our Knowledge construct is based on the empirical data collected through our experiments.  It is measured by the number of correct answers in a multiple choice questionnaire subjects obtained within our experimental study. </p>
                <br></br>

                <h3 className={headers}>Mental Workload</h3>
                    <p className={contentWords}>Mental workload measures whether a given task meets or exceeds the capability of a worker. In addition to considering the requirements of the task and the characteristics of the worker, this measurement also accounts for external factor such as environmental conditions, time constraints, repetitiveness of the given task, and worker fatigue (Hart and Staveland, 1988; Eggemeier, 1988, ISO, 2000).  </p>
                    <p className={contentWords}>Perspective One: Exclusively focuses on how a worker adapts to a given task.</p>
                    <p className={contentWords}>Perspective Two: The results of a relationship between the task and the worker’s skills and resources (Díaz, Rubio, Martín, and Luceño, 2010; Ferrer and Dalmau, 2004; Young et al., 2015).  </p>
                    <p className={contentWords}>An ideal mental workload would balance the intensity and duration of the effort to successfully complete the task at hand (ISIO, 1991; Pretoriius and Cillers, 2007; Young et al., 2015). The worker should be exerted to their full potential without exceeding their capabilities. If the worker’s capabilities are stretched too thin, their efficiency of completing the task can go down. However, if the worker is underloaded, or not stimulated enough, their completion of the task is at risk of being suboptimal (Young and Stanton, 2002; Wilson and Rajjan, 1995; Young et al., 2015). Mental Workload can be increased or decreased by adding or subtracting additional resources.</p>
                <br></br>

                {/* <h3 className={headers}>Usability</h3>
                    <p className={contentWords}>We utilize the System Usability Scale (SUS), and tailored the questionnaires based on our experiment design. (Brooke, J. (1996). Sus: a “quick and dirty” usability. Usability evaluation in industry, 189(3).) </p>
                    <p className={contentWords}>There is no absolute measure of usability. Instead, it is a relative measure of the appropriateness to the desired context or purpose. Usability should cover the following aspects: </p>
                    <p className={contentWords}>Effectiveness: the ability to complete the task, as well as the quality of the output</p>
                    <p className={contentWords}>Efficiency: the level of resources consumed while performing the task</p>
                    <p className={contentWords}>Satisfaction: the user's subjective reaction to the task</p>
                    <br></br>
                    <h2 className={subheaders}>Usability - Ease of Use</h2>
                        <p className={contentWords}>The usability regarding Ease of Use refers to the perceived effectiveness, efficiency, and satisfaction that a participant feels towards the communication technology utilized.</p>
                        <br></br>
                    <h2 className={subheaders}>Usability - Topic of Discussion</h2>
                    <p className={contentWords}>The usability regarding Task Experience refers to the perceived effectiveness, efficiency, and satisfaction that a participant feels towards their performance of the task.</p> */}

                
                <br></br>
                <h3 className={headers}>Shared Situational Awareness</h3>
                    <p className={contentWords}>Shared situation awareness is related to the participants’ understanding of the environment. However, Carroll, Rosson, Convertino, and Ganoe (2006) argue that analysing shared mental models is not sufficient for assessing team communication during coordinated activity and that situational awareness must be taken into account. Motivated initially by the design of human interfaces for avionics and flight control systems, the concept of situation awareness emerged in the late 1980’s. The seminal work by Endsley (1988) defines situation awareness (SA) as “the perception of the elements in the environment within a volume of time and space, the comprehension of their meaning, and the projection of their status in the near future”. In the intervening years, SA has become a substantial topic in the general human factors research community with applications as diverse as air traffic control, power systems operation, emergency management and robotic teleoperation for health care, oil and gas exploration, hazardous environments, and mining, among others. SA research has even extended to measure the collaborative performance of teams (Gorman et al., 2006; Salas, et al., 1995). With respect to shared situation awareness (SSA) in teams, the SA of the individuals involved is important. However, some researchers have noted that not only must there be similarity between individual’s SA, but also the individuals’ SA must be accurate (Saner, Bolstad, Gonzalez, and Cuevas, 2009). </p>
                <br></br>
                <h3 className={headers}>Confidence in Immediate Performance</h3>
                    <p className={contentWords}>Our Performance construct is separated into immediate and future task performance.  It is based on the empirical data collected through surveys and objective task performance during our experiments. Objective task performance was measured by the number of tasks subjects were able to complete successfully within our experimental study. Immediate performance is related to a subject’s confidence in their ability to perform the task immediately following the training.</p>
                <br></br>
                <h3 className={headers}>Confidence in Future Performance</h3>
                    <p className={contentWords}>Our Performance construct is separated into immediate and future task performance.  It is based on the empirical data collected through surveys and objective task performance during our experiments. Objective task performance was measured by the number of tasks subjects were able to complete successfully within our experimental study. Future performance is related to a subject’s confidence in their ability to perform the task after one to two weeks time following the training. </p>
                <br></br>
            

            <h1 className={mainWords}>Citations</h1>
                {/* <h3 className={headers}>Knowledge</h3>
                    <p></p>
                <br></br> */}
                <h3 className={headers}>Mental Workload</h3>
                    <a className={contentWords} href='https://doi.org/10.1186/s41155-017-0061-0'>Soria-Oliver, M., López, J.S. and Torrano, F. Relations between mental workload and decision-making in an organizational setting. Psicol. Refl. Crít. 30, 7 (2018).</a>
                <br></br>
                {/* <h3 className={headers}>Usability (Task Experience and Ease of Use)</h3>
                    <p className={contentWords}>Brooke, J. (1996). Sus: a “quick and dirty’usability. Usability evaluation in industry, 189(3).</p> */}
                <h3 className={headers}>Confidence in Immediate & Future Performance</h3>
                    <a className={contentWords} href='https://sites.google.com/iastate.edu/scottie/publications?authuser=0'>Information about our experiment is linked here.</a>
                <br></br>
                <h3 className={headers}>Shared Situational Awareness</h3>
                    <a className={contentWords} href='https://www.samatters.com/shared-situational-awareness/'>Shared Situational Awareneess - Situational Awareness Matters!</a><br></br>
                    <a className={contentWords} href='https://onlinelibrary.wiley.com/doi/abs/10.1002/9781119636113.ch17'>SITUATIONAL AWARENES - Handbook of Human Factors and Ergonomics - Wiley Online Library</a>
                    <p className={contentWords}>Bolstad, Cheryl and Cuevas, H and Wang-Costello, J and Endsley, Mica and Angell, Linda. (2010). Measurement of situation awareness for automobile technologies of the future.</p>
        </div>
    )
}

export default Descriptions;