import React from 'react';
import { withStyles, Button, StepButton } from "@material-ui/core";
import Header from '../Header';
import ConfidencePerformanceQuestions from '../constructQuestions/ConfidencePerformance';
import { Step, Stepper } from "@material-ui/core"
import {Link} from 'react-router-dom';

/**
 * css constant styles for different components
 * @param {*} theme
 * @returns
 */
const styles = theme => ({
    submitButton: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 700,
      fontSize: "25px",
      textTransform: "lowercase",
      color: "#FFFFEE",
      backgroundColor: "#1E2124",
    },
    container: {
        marginLeft: 150,
        width: "85%",
        height: 500,
        marginTop: 100,
        display: "fixed",
    },
    stepper: {
        backgroundColor: "rgba(244, 244, 244, 1)",
        marginLeft: "-7%",
        color: "black",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "30px",
        textTransform: "lowercase",
    },
    disclaimer: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "17px",
        textAlign: "left",
        color: "#1E2124",
    },
});

/**
 * renders the questions and the submit button
 * calls the questionBoxes component which renders all the quiz questions
 */
class QuestionsSSA extends React.Component {
    state = {
        isOpen: false,
        title: null
    };
    //opens the popup
    openModal = (e) => {
        this.setState({isOpen: true});
        }
    //closes the popup
    closeModal = () => {
        this.setState({isOpen: false});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Header />
                <div className={classes.container} style={{ fontFamily: "Open Sans, sans-serif" }}>
                    <br></br><br></br>
                    <Stepper activeStep={4} nonLinear alternativeLabel className={classes.stepper}>
                        <Step>
                            <StepButton><Link to="/questions-intro">Introduction</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-demographics">Demographics</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-meeting_profile">Meeting Profile</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-mental_workload_ssa">Mental Workload & Shared Situational Awareness</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton>Confidence in Performance</StepButton>
                        </Step>
                        <Step>
                            <StepButton>Recommendation Results</StepButton>
                        </Step>
                        <Step>
                            <StepButton>Specific Follow-up Questions</StepButton>
                        </Step>
                        <Step>
                            <StepButton>General Follow-ups</StepButton>
                        </Step>
                    </Stepper>

                    <ConfidencePerformanceQuestions onClick={this.openModal}/>
                    <br></br><br></br><br></br>
                </div>
            </>

        );
    }
}

export default withStyles(styles)(QuestionsSSA);
