import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Dialog from "@material-ui/core/Dialog";
import { styled } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles, Typography, Button } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';
import { setScores } from '../../features/scoresSlice';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    }
}));

/**
 * @returns all the rendered questions and answers + dividers
 */
function ConfidencePerformanceQuestions() {
    //text styles call
    const { questionWords, answerWords,divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var initialState = {
        q12: ("q12" in answers["confperf"]) ? answers["confperf"]["q12"] : "q12a2", // How important is it for participants to be confident in performing the tasks described immediately after this meeting (e.g. participants have been trained on a task that they can perform immediately after leaving)?
        q13: ("q13" in answers["confperf"]) ? answers["confperf"]["q13"] : "q13a2", // How important is it for participants to be confident in performing the tasks described one to two weeks after this meeting (e.g. participants have been trained on a task that they can perform a week after the meeting)?
    }
    // const [state, setState] = React.useState({
    //     q12: "q12a2", // How important is it for participants to be confident in performing the tasks described immediately after this meeting (e.g. participants have been trained on a task that they can perform immediately after leaving)?
    //     q13: "q13a2", // How important is it for participants to be confident in performing the tasks described one to two weeks after this meeting (e.g. participants have been trained on a task that they can perform a week after the meeting)?
    // });
    const [state, setState] = React.useState(initialState);

    const [hasFollowup, setHasFollowup] = React.useState(false);
    // const [followupLink, setFollowupLink] = React.useState("/questions-tel_followups");
    const followupLink = "/results";

    const [confperfScores, setConfPerfcores] = React.useState({
        tel: {
            q12: 0,
            q13: 0,
        },
        vr: {
            q12: 0,
            q13: 0,
        },
        f2f: {
            q12: 0,
            q13: 0,
        },
    });

    const [immediatePerfIsOpen, setImmediatePerfIsOpen] = React.useState(false);
    const [futurePerfIsOpen, setFuturePerfIsOpen] = React.useState(false);

    const scores = useSelector((s) => s.scores);

    /**
     * updates follow-up decision every time theres a state change
     */
    React.useEffect(() => {
        // window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        var telScores = scores.tel;
        var vrScores = scores.vr;
        var f2fScores = scores.f2f;

        var telScore = Object.keys(telScores).map(
            section => Object.keys(telScores[section]).map(
                questionId => telScores[section][questionId]
            )
        ).reduce((a, b) => a + b.reduce((x, y) => x + y, 0), 0); // Score3

        var vrScore = Object.keys(vrScores).map(
            section => Object.keys(vrScores[section]).map(
                questionId => vrScores[section][questionId]
            )
        ).reduce((a, b) => a + b.reduce((x, y) => x + y, 0), 0); // Score2

        var f2fScore = Object.keys(f2fScores).map(
            section => Object.keys(f2fScores[section]).map(
                questionId => f2fScores[section][questionId]
            )
        ).reduce((a, b) => a + b.reduce((x, y) => x + y, 0), 0); // Score1

        // if (f2fScore > telScore && f2fScore > vrScore) {
        //     setFollowupLink("/questions-f2f_followups");
        //     dispatch(setScores({how: "final", section: "rec", questionId: "section", score: "f2f"}));
        // } else if (vrScore > f2fScore && vrScore > telScore) {
        //     setFollowupLink("/questions-vr_followups");
        //     dispatch(setScores({how: "final", section: "rec", questionId: "section", score: "vr"}));
        // } else if (telScore > f2fScore && telScore > vrScore) {
        //     setFollowupLink("/questions-tel_followups");
        //     dispatch(setScores({how: "final", section: "rec", questionId: "section", score: "tel"}));
        // }
        // console.log(f2fScore);
        // console.log(vrScore);
        // console.log(telScore);
        // console.log(followupLink);

        setHasFollowup(!Object.keys(state).map(questionId => state[questionId]).includes(null));

        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: "confperf", questionId: k, answer: state[k]}))
        ));
        setImmedPerformance();
        setFutPerformance();
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "confperf", questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
        var howScore = {f2f: 0, vr: 0, tel: 0};
        var section = "confperf";
        if (event.target.name === "q12") {
            if (event.target.value === "q12a1") {
                howScore.tel = 1;
            } else if (event.target.value === "q12a2") {
                howScore.f2f = 1;
                howScore.vr = 1;
            } else if (event.target.value === "q12a3") {
                howScore.f2f = 1;
                howScore.vr = 1;
            }
        } else if (event.target.name === "q13") {
            if (event.target.value === "q13a1") {
                howScore.tel = 1;
            } else if (event.target.value === "q13a2") {
                howScore.f2f = 1;
                howScore.vr = 1;
            } else if (event.target.value === "q13a3") {
                howScore.f2f = 1;
                howScore.vr = 1;
            }
        }
        var prevScores = {...confperfScores};
        for (var how in howScore) {
            prevScores[how][event.target.name] = howScore[how];
            setConfPerfcores(prevScores);
            dispatch(setScores({how: how, section: section, questionId: event.target.name, score: howScore[how]}));
        }
    };

    /**
     * sets the immediate performance slice value
     */
    const setImmedPerformance = () => {
        if (state.q12 === "q12a1") { //high
            dispatch(setDegree({section: "immediate_perf", degree: "Virtual Reality"}));
            // console.log("Virtual Reality"); //for testing
        }
        else if (state.q12 === "q12a2") { //med
            dispatch(setDegree({section: "immediate_perf", degree: "Face-to-Face"}));
            // console.log("Face-to-Face"); //for testing
        }
        else if (state.q12 === "q12a3") { //low
            dispatch(setDegree({section: "immediate_perf", degree: "Teleconference"}));
            // console.log("Teleconference"); //for testing
        }
    };

    /**
     * sets the future performance slice value
     */
     const setFutPerformance = () => {
        if (state.q13 === "q13a1") { //high
            dispatch(setDegree({section: "future_perf", degree: "Virtual Reality"}));
            // console.log("Virtual Reality"); //for testing
        }
        else if (state.q13 === "q13a2") { //med
            dispatch(setDegree({section: "future_perf", degree: "Face-to-Face"}));
            // console.log("Face-to-Face"); //for testing
        }
        else if (state.q13 === "q13a3") { //low
            dispatch(setDegree({section: "future_perf", degree: "Teleconference"}));
            // console.log("Teleconference"); //for testing
        }
    };

    /**
     * Opens the popup of the corresponding table cell
     * NOTE: does not open popup when a "degree" button is clicked
     * @param {*} e 
     */
    const openImmediatePerfModal = (e) => {
        setImmediatePerfIsOpen(true);
    }
    /**
     * Closes the popup 
     */
    const closeImmediatePerfModal = () => {
        setImmediatePerfIsOpen(false);
    }

    /**
     * Opens the popup of the corresponding table cell
     * NOTE: does not open popup when a "degree" button is clicked
     * @param {*} e 
     */
     const openFuturePerfModal = (e) => {
        setFuturePerfIsOpen(true);
    }
    /**
     * Closes the popup 
     */
    const closeuturePerfModal = () => {
        setFuturePerfIsOpen(false);
    }

    const dispatch = useDispatch()

    return(
        <Root>
        <div>
            {/* Confidence Performance */}
            <Divider textAlign="right" className={divWords} onClick={openImmediatePerfModal}>Confidence in Immediate Performance &#9432;</Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How important is it for participants to be confident in performing the tasks described immediately after this meeting (e.g. participants have been trained on a task that they can perform immediately after leaving)?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q12" value={state.q12}>
                        <FormControlLabel
                            value="q12a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Low importance</Typography>}
                        />
                        <FormControlLabel
                            value="q12a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Medium importance</Typography>}
                        />
                        <FormControlLabel
                            value="q12a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>High importance</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords} onClick={openFuturePerfModal}>Confidence in Future Performance &#9432;</Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How important is it for participants to be confident in performing the tasks described one to two weeks after this meeting (e.g. participants have been trained on a task that they can perform a week after the meeting)?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q13" value={state.q13}>
                        <FormControlLabel
                            value="q13a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Low importance</Typography>}
                        />
                        <FormControlLabel
                            value="q13a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Medium importance</Typography>}
                        />
                        <FormControlLabel
                            value="q13a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>High importance</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <br></br><br></br>
            {/* <Link to="/questions-ssa"><Button className={submitButton}>
                Back
            </Button></Link> */}
            {/* {
                hasFollowup
                ?
                <Link to={followupLink}><Button className={submitButton} style={{float: 'right'}}>
                    Next
                </Button></Link>
                :
                ""
            } */}
            <Link to={followupLink}><Button className={submitButton} style={{float: 'right'}}>
                Next
            </Button></Link>
        </div>
        <Dialog open={immediatePerfIsOpen} onClose={closeImmediatePerfModal} maxWidth='md' fullWidth={true}>
            <DialogTitle margin='10%' align='center'>Confidence in Immediate Performance</DialogTitle>
            <DialogContent>
                <DialogContentText margin='10%' align='center'>
                How confident the participant is in completing the required task(s) immediately after training.<br></br>
                </DialogContentText>
                </DialogContent>
                <Button onClick={closeImmediatePerfModal}>Close</Button>
        </Dialog>
        <Dialog open={futurePerfIsOpen} onClose={closeuturePerfModal} maxWidth='md' fullWidth={true}>
            <DialogTitle margin='10%' align='center'>Confidence in Future Performance</DialogTitle>
            <DialogContent>
                <DialogContentText margin='10%' align='center'>
                How confident the participant is in completing the required task(s) 1 to 2 weeks after training.<br></br>
                </DialogContentText>
                </DialogContent>
                <Button onClick={closeuturePerfModal}>Close</Button>
        </Dialog>
        </Root>
    );
}

export default ConfidencePerformanceQuestions;