import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    // consent: {},
    meeting: {},
    mentalwl: {},
    ssa: {},
    confperf: {},
    tel: {},
    vr: {},
    f2f: {},
    // followup: {},
    general: {},
    demographics: {},
    // feedback: {},
}

export const answersSlice = createSlice({
    name: 'answers',
    initialState,
    reducers: {
        setAnswer: (state, action) => {
            state[action.payload.section][action.payload.questionId] = action.payload.answer;
        },
    },
})

export const { setAnswer } = answersSlice.actions

export default answersSlice.reducer