import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import { Prompt } from 'react-router'
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { Button, ButtonGroup } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { Select, MenuItem } from '@material-ui/core';

import { renderRecommendation } from "../../util";
import { setScores } from '../../features/scoresSlice';

const initial_data = [
  {
    Construct: "Knowledge",
    Definition:
      "How well the participant retains learned knowledge about the topic of discussion.",
    Recommendation: "Face-to-Face",
    Average: "Face-to-Face",
    section: "knowledge",
  },

  {
    Construct: "Mental Workload",
    Definition:
      "The ease of mental effort that the participants need to expend to carry out the purpose of the meeting without overloading the participant. ",
    Recommendation: "Face-to-Face",
    Average: "Face-to-Face",
    section: "mental_workload",
  },

  // {
  //   Construct: "Usability: Ease of Use of Remote Meeting Tool",
  //   Definition:
  //     "The perceived ease of use of the communication tools to the participants.",
  //   Recommendation: "Face-to-Face",
  //   Average: "Face-to-Face",
  //   section: "usability_eau",
  // },

  // {
  //   Construct: "Usability: Topic of Discussion",
  //   Definition: "How easy is the topic of discussion for the participants.",
  //   Recommendation: "Face-to-Face",
  //   Average: "Face-to-Face",
  //   section: "usability_te",
  // },

  {
    Construct: "Shared Situational Awareness",
    Definition:
      "The extent to which participants have a commonly understood mental model of a situation (I.e., what is currently happening and what is going to happen).",
    Recommendation: "Face-to-Face",
    Average: "Face-to-Face",
    section: "ssa",
  },

  {
    Construct: "Immediate Performance",
    Definition:
      "How confident is the participant in completing the required task(s) immediately after training. ",
    Recommendation: "Face-to-Face",
    Average: "Face-to-Face",
    section: "immediate_perf",
  },

  {
    Construct: "Future Performance",
    Definition:
      "How confident is the participant in completing the required task(s) after one to two weeks following training. ",
    Recommendation: "Face-to-Face",
    Average: "Face-to-Face",
    section: "future_perf",
  },
];

var finalRec = "";

/**
 * Class for rendering the entire table
 */
export function ResultsTable(props) {
  const quiz = useSelector((s) => s.quiz);
  const [data, setData] = React.useState(initial_data);
  const [state, setState] = React.useState({
    planState: "",
    resultsSaved: false,
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    const newdata = data.map((section) => {
      return {
        ...section,
        Recommendation: quiz[section.section],
      };
    });
    setData(newdata);
    // eslint-disable-next-line
  }, []);

  /**
   * Sets the Recommendation of that row to virtual reality
   * @param {*} i index of the row
   */
  const updateRecVR = (i) => {
    let prevState = [...data];
    prevState[i].Recommendation = "Virtual Reality";
    setData(prevState);
    props.setDegree({
      section: prevState[i].section,
      degree: "Virtual Reality",
    });
  };

  /**
   * Sets the Recommendation of that row to f2f
   * @param {*} i index of the row
   */
  const updateRecF2F = (i) => {
    let prevState = [...data];
    prevState[i].Recommendation = "Face-to-Face";
    setData(prevState);
    props.setDegree({ section: prevState[i].section, degree: "Face-to-Face" });
  };

  /**
   * Sets the Recommendation of that row to teleconference
   * @param {*} i index of the row
   */
  const updateRecTel = (i) => {
    let prevState = [...data];
    prevState[i].Recommendation = "Teleconference";
    setData(prevState);
    props.setDegree({
      section: prevState[i].section,
      degree: "Teleconference",
    });
  };

  /**
   * Sets the Recommendation of that row to vr/f2f but med
   * @param {*} i index of the row
   */
  const updateRecVRF2F = (i) => {
    let prevState = [...data];
    prevState[i].Recommendation = "Virtual Reality or Face-to-Face";
    setData(prevState);
    props.setDegree({
      section: prevState[i].section,
      degree: "Virtual Reality or Face-to-Face",
    });
  };

  /**
   * Sets the Recommendation of that row to vr/f2f but high
   * @param {*} i index of the row
   */
  const updateRecF2FVR = (i) => {
    let prevState = [...data];
    prevState[i].Recommendation = "Face-to-Face or Virtual Reality";
    setData(prevState);
    props.setDegree({
      section: prevState[i].section,
      degree: "Face-to-Face or Virtual Reality",
    });
  };

  /**
   * reads the construct at the index as well as the previous state and
   * calls updateRec[tel, f2f, vr] as needed
   * @param {*} i index of row
   * @param {*} deg new degree selection
   */
  const updateRec = (i, deg) => {
    let prevState = [...data];

    // console.log(deg);

    //all have tel as low
    if (deg === "low") updateRecTel(i);
    //if construct type 1 (knowledge)
    else if (prevState[i].Construct === "Knowledge") {
      //deg med or high
      if (deg === "med") updateRecVRF2F(i); //set vr/f2f
      else updateRecF2FVR(i); //high
    } else if (prevState[i].Construct === "Shared Situational Awareness") {
      //if deg med
      if (deg === "med") updateRecVR(i); //set vr
      //else deg high
      else updateRecF2F(i); //set f2f
    }
    //else if construct type 2
    else if (prevState[i].Construct === "Mental Workload") {
      //if deg med
      if (deg === "med") updateRecVRF2F(i); //set vr
      //else deg high
      else updateRecF2FVR(i); //set f2f
    }
    //else construct type 3
    else {
      //if deg med
      if (deg === "med") updateRecVRF2F(i); //set f2f
      //else deg high
      else updateRecF2FVR(i); //set vr
    }
  };

  /**
   *
   * @returns a string stating the average recommendation value
   * deals with edge cases such as if usability means that f2f is the only case possible
   * or if virtual reality is impossible
   */
  const CalcAv = () => {
    var i, vr, f2f, tel;
    tel = vr = f2f = 0;
    let prevState = [...data];
    // if (prevState[2].Recommendation === "Face-to-Face Only")
    //   return "Face-to-Face Only";
    for (i = 0; i < prevState.length; i++) {
      if (prevState[i].Recommendation === "Teleconference") tel++;
      if (prevState[i].Recommendation === "Face-to-Face") f2f++;
      if (prevState[i].Recommendation === "Virtual Reality") vr++;
      if (
        prevState[i].Recommendation === "Virtual Reality or Face-to-Face" ||
        prevState[i].Recommendation === "Face-to-Face or Virtual Reality"
      ) {
        // vr++;
        // f2f++;
        vr += 0.5;
        f2f += 0.5;
      }
      // if (
      //   prevState[i].Recommendation === "Virtual Reality or Face-to-Face"
      // ) {
      //   vr += 0.75;
      //   f2f += 0.25;
      // }
      // if (
      //   prevState[i].Recommendation === "Face-to-Face or Virtual Reality"
      // ) {
      //   vr += 0.25;
      //   f2f += 0.75;
      // }
    }
    // if (
    //   prevState[2].Recommendation ===
    //     "Teleconference, Virtual Reality Impossible" ||
    //   prevState[2].Recommendation === "Face-to-Face, Virtual Reality Impossible"
    // ){
    //   if (tel >= f2f) return "Teleconference";
    //   return "Face-to-Face";
    // }
    var rec = "";
    // if (tel >= vr && tel >= f2f) {
    //   rec = "Teleconference";
    // } else if (vr >= tel && vr >= f2f) {
    //   rec = "Virtual Reality";
    // } else {
    //   rec = "Face-to-Face";
    // }
    if (tel == vr && tel == f2f) {
      rec = "Virtual Reality or Teleconference or Face-to-Face";
    } else if (tel > vr && tel > f2f) {
      rec = "Teleconference";
    } else if (vr > tel && vr > f2f) {
      rec = "Virtual Reality";
    } else if (f2f > tel && f2f > vr) {
      rec = "Face-to-Face";
    } else if (vr >= tel && vr >= f2f) {
      if (vr == tel) {
        rec = "Virtual Reality or Teleconference";
      } else if (vr == f2f) {
        rec = "Virtual Reality or Face-to-Face";
      } else {
        rec = "Virtual Reality";
      }
    } else if (tel >= vr && tel >= f2f) {
      if (tel == vr) {
        rec = "Virtual Reality or Teleconference";
      } else if (tel == f2f) {
        rec = "Teleconference or Face-to-Face";
      } else {
        rec = "Teleconference";
      }
    } else {
      rec = "Face-to-Face";
    }
    dispatch(setScores({how: "final", section: "rec", questionId: "section", score: rec}));
    dispatch(setScores({how: "final", section: "plan", questionId: "section", score: state.planState}));
    return rec;
  };

  /**
   *
   * @param {*} rec the type of recommendation to be counted and returned
   * @returns the number of times a certain recommendation shows up
   */
  const CalcScores = (rec) => {
    var i, vr, f2f, tel;
    tel = vr = f2f = 0;
    let prevState = [...data];
    for (i = 0; i < prevState.length; i++) {
      if (prevState[i].Recommendation === "Teleconference" && rec === "tel")
        tel++;
      else if (prevState[i].Recommendation === "Face-to-Face" && rec === "f2f")
        f2f++;
      else if (
        prevState[i].Recommendation === "Virtual Reality" &&
        rec === "vr"
      )
        vr++;
      else if (
        prevState[i].Recommendation === "Virtual Reality or Face-to-Face"
      ) {
        vr += 0.5;
        f2f += 0.5;
        // vr += 0.75;
        // f2f += 0.25;
      } else if (
        prevState[i].Recommendation === "Face-to-Face or Virtual Reality"
      ) {
        vr += 0.5;
        f2f += 0.5;
        // vr += 0.25;
        // f2f += 0.75;
      }
    }
    // if (tel >= vr && tel >= f2f && rec === "tel") return tel;
    // if (vr >= tel && vr >= f2f && rec === "vr") return vr;
    // return f2f;
    if (rec === "tel") return tel;
    if (rec === "vr") return vr;
    return f2f;
  };

  /**
   *
   * @param {*} i the index on the table and thus the data
   * @returns if the construct degree at the current index is Low or not
   */
  const isLow = (i) => {
    let prevState = [...data];
    if (prevState[i].Recommendation === "Teleconference") return true;
    return false;
  };

  /**
   *
   * @param {*} i the index on the table and thus the data
   * @returns if the construct degree at the current index is Med or not
   */
  const isMed = (i) => {
    let prevState = [...data];
    //knowledge
    if (prevState[i].Recommendation === "Virtual Reality or Face-to-Face")
      return true;
    //mental, ssa
    if (
      prevState[i].Recommendation === "Virtual Reality" &&
      (["Mental Workload", "Shared Situational Awareness"].includes(prevState[i].Construct))
    )
      return true;
    //u-eou, u-ta, imm-perf, fut-perf
    if (
      prevState[i].Recommendation === "Face-to-Face" &&
      (["Usability: Ease of Use of Remote Meeting Tool", "Usability: Topic of Discussion", 
        "Immediate Performance", "Future Performance"].includes(prevState[i].Construct))
    )
      return true;
    return false;
  };

  /**
   *
   * @param {*} i the index on the table
   * @returns the properly shaded button. Allows for a toggle effect using different colorings
   */
  const renderButtons = (i) => {
    //FOR THE LOW/MED/HIGH BUTTONS
    var low = (
      <ButtonGroup>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#FFFFEE",
            backgroundColor: "#2E3338",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "low")}
        >
          low
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "med")}
        >
          med
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "high")}
        >
          high
        </Button>
      </ButtonGroup>
    );
    var med = (
      <ButtonGroup>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "low")}
        >
          low
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#FFFFEE",
            backgroundColor: "#2E3338",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "med")}
        >
          med
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "high")}
        >
          high
        </Button>
      </ButtonGroup>
    );
    var high = (
      <ButtonGroup>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "low")}
        >
          low
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "med")}
        >
          med
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#FFFFEE",
            backgroundColor: "#2E3338",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "high")}
        >
          high
        </Button>
      </ButtonGroup>
    );
    //FOR THE WORST/BEST BUTTONS
    var worst = (
      <ButtonGroup>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#FFFFEE",
            backgroundColor: "#2E3338",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "low")}
        >
          worst
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "med")}
        >
          med
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "high")}
        >
          best
        </Button>
      </ButtonGroup>
    );
    var middle = (
      <ButtonGroup>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "low")}
        >
          worst
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#FFFFEE",
            backgroundColor: "#2E3338",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "med")}
        >
          med
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "high")}
        >
          best
        </Button>
      </ButtonGroup>
    );
    var best = (
      <ButtonGroup>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "low")}
        >
          worst
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#2E3338",
            backgroundColor: "rgba(244, 244, 244, 1)",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "med")}
        >
          med
        </Button>
        <Button
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            color: "#FFFFEE",
            backgroundColor: "#2E3338",
            borderColor: "#2E3338",
            borderWidth: 2,
            textTransform: "lowercase",
          }}
          onClick={() => updateRec(i, "high")}
        >
          best
        </Button>
      </ButtonGroup>
    );
    //return worst and best
    var worstBestConstructs = ["Mental Workload", "Usability: Ease of Use of Remote Meeting Tool", "Usability: Topic of Discussion", "Shared Situational Awareness"];
    if (worstBestConstructs.includes(data[i].Construct)) {
      if (isLow(i)) return worst;
      else if (isMed(i)) return middle;
      else return best;
    }
    //return l/m/h
    if (isLow(i)) return low;
    else if (isMed(i)) return med;
    else return high;
  };

  /**
   *
   * @returns the data rows in the table, including the low/med/high button group
   */
  const tableTable = () => {
    return data.map((data, index) => {
      // console.log(data);
      const { Construct, Definition, Recommendation } = data;
      return (
        <TableBody key={index}>
          <TableRow>
            <TableCell
              align="center"
              style={{
                fontFamily: "Open Sans, sans-serif",
                color: "#1E2124",
                backgroundColor: "rgba(244, 244, 244, 1)",
                fontWeight: 700,
                fontSize: 19,
                borderColor: "#1E2124",
                borderBottomWidth: "1px",
              }}
            >
              {Construct}
              <Tooltip title={Definition} placement="right">
                <IconButton>&#9432;</IconButton>
              </Tooltip>
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontFamily: "Open Sans, sans-serif",
                color: "#1E2124",
                backgroundColor: "rgba(244, 244, 244, 1)",
                fontWeight: 700,
                fontSize: 19,
                borderColor: "#1E2124",
                borderBottomWidth: "1px",
              }}
              key={Recommendation}
            >
              {renderButtons(index)}
            </TableCell>
            <TableCell
              align="center"
              style={{
                fontFamily: "Open Sans, sans-serif",
                color: "#1E2124",
                backgroundColor: "rgba(244, 244, 244, 1)",
                fontWeight: 700,
                fontSize: 19,
                borderColor: "#1E2124",
                borderBottomWidth: "1px",
              }}
            >
              {renderRecommendation(Recommendation)}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    });
  };

  /**
   *
   * @returns the table header values
   */
  const tableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              fontFamily: "Open Sans, sans-serif",
              color: "white",
              backgroundColor: "#2E3338",
              fontWeight: 710,
              fontSize: 23,
              borderColor: "#1E2124",
              borderBottomWidth: "1px",
              textAlign: "center",
            }}
          >
            Construct
          </TableCell>
          <TableCell
            style={{
              fontFamily: "Open Sans, sans-serif",
              color: "white",
              backgroundColor: "#2E3338",
              fontWeight: 710,
              fontSize: 23,
              borderColor: "#1E2124",
              borderBottomWidth: "1px",
              textAlign: "center",
            }}
          >
            Degree Required
          </TableCell>
          <TableCell
            style={{
              fontFamily: "Open Sans, sans-serif",
              color: "white",
              backgroundColor: "#2E3338",
              fontWeight: 710,
              fontSize: 23,
              borderColor: "#1E2124",
              borderBottomWidth: "1px",
              textAlign: "center",
            }}
          >
            Recommendation
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };
  const tableSpanTel = () => {
    return (
      <TableRow>
        {/* tel rec */}
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            borderColor: "#1E2124",
            borderBottomWidth: "0px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        />
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          # of Teleconference (Remote):
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          {CalcScores("tel")}
        </TableCell>
      </TableRow>
    );
  };
  const tableSpanVR = () => {
    return (
      <TableRow>
        {/* vr rec */}
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 22,
            borderColor: "#1E2124",
            borderBottomWidth: "0px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        />
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          # of VR (Remote):
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          {CalcScores("vr")}
        </TableCell>
      </TableRow>
    );
  };
  const tableSpanF2F = () => {
    return (
      <TableRow>
        {/* f2f rec */}
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "0px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        />
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          # of Face-to-Face:
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 18,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          {CalcScores("f2f")}
        </TableCell>
      </TableRow>
    );
  };
  const tableSpanRec = () => {
    return (
      <TableRow>
        {/* we recommend */}
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 22,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        />
        <TableCell
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 22,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          We Recommend:
        </TableCell>
        <TableCell
          align="center"
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontWeight: 700,
            fontSize: 22,
            borderColor: "#1E2124",
            borderBottomWidth: "1px",
            color: "white",
            backgroundColor: "#2E3338",
          }}
        >
          {/* {finalRec = renderRecommendation(CalcAv())} */}
          {finalRec}
        </TableCell>
      </TableRow>
    );
  };

  const handlePlanningSelectChange = (event) => {
    setState({planState: event.target.value, resultsSaved: true});
  }

  const updateFollowupLink = () => {
    var ret = "";
    if (finalRec.startsWith("Teleconference")) {
      ret = "/questions-tel_followups";
    } else if (finalRec.startsWith("Virtual Reality")) {
      ret = "/questions-vr_followups";
    } else {
      ret = "/questions-f2f_followups";
    }
    return ret;
  }

  /**
   *
   * @returns the whole table - headers, body, spanning cells at the bottom
   */
  return (
    <>
      {/* <Prompt
          when={!state.resultsSaved}
          message='You have not selected how you plan to attend this meeting, are you sure you want to leave?'
      /> */}
      {finalRec = renderRecommendation(CalcAv())}
      <h1 className={props.classes.mainWords} >Your best option is... {finalRec}</h1>
      <div>
        <TableContainer component={Paper}>
          <Table id="data" aria-label="simple table">
            {tableHeader()}
            {tableTable()}
            <TableBody>
              {tableSpanTel()}
              {tableSpanVR()}
              {tableSpanF2F()}
              {tableSpanRec()}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Render a link to the appendix*/} 
        <div style={{textAlign: "center"}}>
            <Link to='/descriptions' className={props.classes.link} target="_blank" rel="noopener noreferrer">Learn more about how we determine our recommendations</Link>
            {/* <Link to='/descriptions' className={props.classes.link}>Learn more about how we determine our recommendations</Link> */}
        </div>

        {/* <hr style={{width: "100%", borderTop: "1px solid black"}}/> */}

        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            {/* <div style={{display: "flex", justifyContent: "center"}}>
                <h1 className={props.classes.mainWords}>How do you plan to attend this meeting?</h1>
            </div>
            
            <div style={{display: "flex", justifyContent: "center", gap: "15px"}}>
                <Select
                    value={state.planState}
                    label="Not Answered"
                    onChange={handlePlanningSelectChange}
                    style={{minWidth: "100px", width: "10%", maxWidth: "200px"}}
                >
                    <MenuItem value={"In-Person"}>In-Person</MenuItem>
                    <MenuItem value={"Virtually"}>Virtually</MenuItem>
                    <MenuItem value={"Unsure"}>Unsure</MenuItem>
                </Select>
            </div> */}
            <Link to={updateFollowupLink()}><Button className={props.classes.submitButton} style={{float: 'right'}}>
                Next
            </Button></Link>
        </div>
      </div>
    </>
  );
}

export default ResultsTable;
