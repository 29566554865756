import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Dialog from "@material-ui/core/Dialog";
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Button, TableBody, TableRow, TableCell } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    },
    plusSign: {
        color: "green",
        fontSize: "40px",
    },
    crossSign: {
        color: "red",
        fontSize: "40px",
    },
}));

var peopleResult, equipmentResult, artifactsResult, hasNext;
function showFollowupResults(state,answerWords,classes) {
    peopleResult = equipmentResult = artifactsResult = "";
    hasNext = false;

    if (state.q25 != null && state.q21 != null && state.q22 != null && state.q24 != null) {
        hasNext = true;
        if (state.q22 != "q22a1" && state.q24 == "q24a1") {
            peopleResult =  
            <p className={answerWords}>
                People: <span className={classes.plusSign}>+</span> Participants have the teleconferencing knowledge to make this meeting successful
            </p>;
        }
        if (state.q22 == "q22a1" || state.q24 != "q24a1") {
            peopleResult =  
            <p className={answerWords}>
                People: <span className={classes.crossSign}>X</span> Participants don't have much experience with teleconferencing and/or need training on teleconferencing tech. To make the meeting successful, ensure everyone can participate by offering a training prior to the meeting.
            </p>;
        }

        if (state.q25 == "q25a1" && state.q21 == "q21a1") {
            equipmentResult =  
            <p className={answerWords}>
                Equipment: <span className={classes.plusSign}>+</span> Participants have access to the equipment they need for a telecon meeting
            </p>;
        }
        if (state.q25 == "q25a2" || state.q21 == "q21a2") {
            equipmentResult =  
            <p className={answerWords}>
                Equipment: <span className={classes.crossSign}>X</span> Participants don't have all of the equipment needed to participate. To make the meeting successful, ensure everyone has a broadband internet connection, laptop with a camera and microphone, or a desktop computer with a camera and microphone.
            </p>;
        }

        if (!state.q14a3) {
            artifactsResult =  
            <p className={answerWords}>
                Artifacts: <span className={classes.plusSign}>+</span> Materials needed for the meeting all have a digital representation that can be shared with attendees
            </p>;
        }
        if (state.q14a3) {
            artifactsResult =  
            <p className={answerWords}>
                Artifacts: <span className={classes.crossSign}>X</span> Materials for the meeting don't all have digital representations. Consider creating digital representations or documents to facilitate teleconferencing.
            </p>;
        }
    }
}

/**
 * @returns all the rendered questions and answers + dividers
 */
function TelFollowupsQuestions() {
    //text styles call
    const { questionWords, answerWords,divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var answerName = "tel";
    var initialState = {
        q14a1: ("q14a1" in answers[answerName]) ? answers[answerName]["q14a1"] : false, // What will you need to access in order to meet the goals of your meeting?
        q14a2: ("q14a2" in answers[answerName]) ? answers[answerName]["q14a2"] : false, // What will you need to access in order to meet the goals of your meeting?
        q14a3: ("q14a3" in answers[answerName]) ? answers[answerName]["q14a3"] : false, // What will you need to access in order to meet the goals of your meeting?
        q14a4: ("q14a4" in answers[answerName]) ? answers[answerName]["q14a4"] : false, // What will you need to access in order to meet the goals of your meeting?
        q14a5: ("q14a5" in answers[answerName]) ? answers[answerName]["q14a5"] : false, // What will you need to access in order to meet the goals of your meeting?
        q25: ("q25" in answers[answerName]) ? answers[answerName]["q25"] : null, // Do all participants have a reliable broadband connection?
        q21: ("q21" in answers[answerName]) ? answers[answerName]["q21"] : null, // Do all the participants have access to the necessary equipment for AR/VR?
        q22: ("q22" in answers[answerName]) ? answers[answerName]["q22"] : null, // How skilled are the participants at using AR/VR?
        q24: ("q24" in answers[answerName]) ? answers[answerName]["q24"] : null, // What is your role in the workplace?
    }
    // const [state, setState] = React.useState({
    //     q14a1: false, q14a2: false, q14a3: false, q14a4: false, q14a5: false, // What will you need to access in order to meet the goals of your meeting?
    //     q25: null, // Do all participants have a reliable broadband connection?
    //     q21: null, // Do all participants have access to the necessary equipment for teleconferencing?
    //     q22: null, // How skilled are the participants at using the teleconferencing platform?
    //     q24: null, // How much training will the participants need to be up to date with the technologies used?
    // });
    const [state, setState] = React.useState(initialState);

    //sets the values for the select multiple question
    // const { 
    //     q14a1, q14a2, q14a3, q14a4, q14a5
    // } = state;

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: answerName, questionId: k, answer: state[k]}))
        ));
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "tel", questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
    };

    //handles question box toggle changes
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        dispatch(setAnswer({section: "tel", questionId: event.target.name, answer: event.target.checked}));
        // console.log(event.target.name);
        // console.log(event.target.checked);
    };

    const dispatch = useDispatch();

    return(
        <Root>
        <div>
            {/* Teleconference Follow-ups */}
            <div>
                <p className={warningWords}>
                    Based upon your answers, we recommend <b>teleconferencing</b> for this meeting. Answer the following questions to determine your organization's readiness for moving this meeting to a teleconferencing platform.
                </p>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">What will you need to access in order to meet the goals of your meeting?</FormLabel>
                    <br></br>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={state.q14a1} onChange={handleChange} name="q14a1" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Nothing</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q14a2} onChange={handleChange} name="q14a2" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Digital documents (e.g. slides, shared PDFs, Word documents)</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q14a3} onChange={handleChange} name="q14a3" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Physical artifacts with no digital representation available (e.g. a machine part)</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q14a4} onChange={handleChange} name="q14a4" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Virtual artifacts (e.g. CAD models)</Typography>}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={state.q14a5} onChange={handleChange} name="q14a5" style={{ color: "grey" }} />}
                            label={<Typography className={answerWords}>Virtual environments</Typography>}
                        />
                    </FormGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">Do all participants have a reliable broadband connection?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q25" value={state.q25}>
                        <FormControlLabel
                            value="q25a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Yes</Typography>}
                        />
                        <FormControlLabel
                            value="q25a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>No</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">Do all participants have access to the necessary equipment for teleconferencing?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q21" value={state.q21}>
                        <FormControlLabel
                            value="q21a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Yes</Typography>}
                        />
                        <FormControlLabel
                            value="q21a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>No</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How skilled are the participants at using the teleconferencing platform?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q22" value={state.q22}>
                        <FormControlLabel
                            value="q22a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>No previous experience</Typography>}
                        />
                        <FormControlLabel
                            value="q22a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Familiar</Typography>}
                        />
                        <FormControlLabel
                            value="q22a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Experienced</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">How much training will the participants need to be up to date with the technologies used?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q24" value={state.q24}>
                        <FormControlLabel
                            value="q24a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>None</Typography>}
                        />
                        <FormControlLabel
                            value="q24a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Less than 1 hour</Typography>}
                        />
                        <FormControlLabel
                            value="q24a3" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>More than 1 hour</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            {showFollowupResults(state,answerWords,classes)}
            {peopleResult}
            {equipmentResult}
            {artifactsResult}
            <br></br><br></br>
            {/* <Link to="/questions-confidence_perf"><Button className={submitButton}>
                Back
            </Button></Link> */}
            {
                (hasNext)
                ?
                <Link to={{pathname: "/questions-general_followups", state: {finalRecLink: "/questions-tel_followups"}}}><Button className={submitButton} style={{float: 'right'}}>
                    Next
                </Button></Link>
                :
                ""
            }
        </div>
        </Root>
    );
}

export default TelFollowupsQuestions;