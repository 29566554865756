import React from 'react'
import { withStyles, StepButton } from "@material-ui/core"
import Header from './Header'
import { Step, Stepper } from "@material-ui/core"

const styles = theme => ({
    container: {
        marginLeft: 150,
        width: "85%",
        height: 500,
        marginTop: 100,
        display: "fixed",
    },
    stepper: {
        backgroundColor: "#A2B6E7",
        marginLeft: "-7%",
        color: "black",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "30px",
        textTransform: "lowercase",
    },
    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    },
});


/**
 * 
 * @returns the end page
 */
class End extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Header />
                <div className={classes.container} style={{ fontFamily: "Open Sans, sans-serif" }}>
                <br></br><br></br>
                    <p className={classes.divWords}>
                        Thank you!
                    </p>
                    <p style={{textAlign: "center"}}>
                        For more information or to provide feedback, please go to <a href='https://projects.vrac.iastate.edu/scottie/'>https://projects.vrac.iastate.edu/scottie/</a>.
                    </p>
                </div>
            </>
        );
    };
}

export default withStyles(styles)(End);