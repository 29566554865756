import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tel: {
        meeting: {},
        mentalwl: {},
        ssa: {},
        confperf: {}
    },
    vr:  {
        meeting: {},
        mentalwl: {},
        ssa: {},
        confperf: {}
    },
    f2f:  {
        meeting: {},
        mentalwl: {},
        ssa: {},
        confperf: {}
    },
    final: {
        rec: {
            section: ""
        },
        plan: {
            section: ""
        },
    }
}

export const scoresSlice = createSlice({
    name: 'scores',
    initialState,
    reducers: {
        setScores: (state, action) => {
            state[action.payload.how][action.payload.section][action.payload.questionId] = action.payload.score;
        },
    },
})

export const { setScores } = scoresSlice.actions

export default scoresSlice.reducer