import React from 'react';
import { Prompt } from 'react-router'
import { connect, useSelector } from "react-redux";
import { Link } from 'react-router-dom';

import Header from './Header'
import { Step, Stepper } from "@material-ui/core"
import { renderRecommendation } from '../util';
import {setDegree} from "../features/quizSlice";
// import {createResponsesRecord} from "../firebase";
import {createResponsesRecord} from "../database";
import ResultsTable from './components/ResultsTable';
// import {getVisitorFingerprint} from "../fingerprint";

import Tooltip from '@mui/material/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import IconButton from '@mui/material/IconButton';
import { Select, MenuItem } from '@material-ui/core';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles, Button, StepButton } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";

/**
 * sets the css styles for the container sizes
 */
// const useStyles = makeStyles(() => ({
//     container: {
//         // backgroundColor: "#545F66", // for testing/sizing
//         marginLeft: 5,
//         width: "99%",
//         height: 570,
//         display: "inline-block",
//         marginTop: 100,
//     },
//     content_container: {
//         margin: "10%",
//         height: "99.8%",
//         scrollbarColor: "blue",
//     },
// }));

/**
 * sets the css styles for the different components
 */
const styles = theme => ({
    mainWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "37px",
        margin: "25px",
        textAlign: "center",
        color: "#1E2124",
    },
    buttonStyles: {
        fontFamily: "Open Sans, sans-serif", 
        color: "#1E2124",
        fontWeight: 700,
        fontSize: 17,
        borderColor: "#25292D",
        borderBottomWidth: "1px",
        textTransform: "lowercase",
    },
    link: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "17px",
        // textTransform: "lowercase",
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
    },
    container: {
        marginLeft: 150,
        width: "85%",
        height: 500,
        marginTop: 100,
        display: "fixed",
    },
});

/**
 * renders the result + table + next steps buttons
 */
class Results extends React.Component {
    //handles the change in state
    state = {
        isOpen: false,
        isActive: false,
        knowledgeIsOpen: false,
        mwIsOpen: false,
        usabilityIsOpen: false,
        SSAIsOpen: false,
        performanceIsOpen: false,
        dataContributedPopup: false,
        openModal: false,
        title: null,
        definition: null,
        planningSelectValue: "",
        savingResults: false,
        resultsSaved: false,
        initialQuizResults: undefined,
    };

    // Todo: Add alert when user reloads page: https://stackoverflow.com/questions/32841757/detecting-user-leaving-page-with-react-router


    componentDidMount = () => {
        this.setState({
            initialQuizResults: {...this.props.quiz}
        });
        window.scrollTo(0, 0);
    }

    shareResults = async(attendancePlan) => {
        this.setState({
            savingResults: true
        });
        // let fingerprint = undefined;
        // try {
        //     fingerprint = await getVisitorFingerprint();
        // }
        // catch(e) {
        //     console.error(e);
        // }

        let convertedQuiz = {...this.props.quiz};
        if(true) {
            for (const property in this.props.quiz) {
                convertedQuiz[property] = renderRecommendation(this.props.quiz[property]);
              }
        }

        try {
            // await createResponsesRecord(
            //     convertedQuiz, 
            //     this.state.initialQuizResults, 
            //     this.props.answers, 
            //     attendancePlan
            // );
            createResponsesRecord(
                convertedQuiz, 
                this.state.initialQuizResults, 
                this.props.answers, 
                attendancePlan
            );
        }
        catch(e) {}
        

        this.setState({
            savingResults: false,
            resultsSaved: true
        });
    }

    //shows the table
    handleShow = () => {
        this.setState({isActive: true});
    };
    
    //hides the table
    handleHide = () => {
        this.setState({isActive: false});
    };

    // /**
    //  * Opens the popup of the corresponding table cell
    //  * NOTE: does not open popup when a "degree" button is clicked
    //  * @param {*} e 
    //  */
    // openModal = (e) => {
    //     this.definition = e.target;
    //     if (e.target.innerText.includes("Knowledge") || e.target.innerText.includes("Mental Workload") || e.target.innerText.includes("Usability") || e.target.innerText.includes("Situational") || e.target.innerText.includes("Performance")) {
    //         this.setState({isOpen: true});
    //         var titleString = e.target.innerHTML;
    //         this.title = titleString.substring(0, titleString.indexOf('<'));
    //         var definitionStr = e.target.innerHTML;
    //         // //descriptionStr = descriptionStr.substring(0, descriptionStr.indexOf('<p>' + 1));
    //         definitionStr = definitionStr.replace('<br>', '');
    //         definitionStr = definitionStr.replace('<br>', '');
    //         definitionStr = definitionStr.replace(this.title, '');
    //         this.definition = definitionStr.substring(definitionStr.indexOf('>') + 1, definitionStr.lastIndexOf('<'));
    //     }
    // }
    /**
     * Opens the popup of the knowledge construct
     * NOTE: does not open popup when a "degree" button is clicked
     * @param {*} e 
     */
    openKnowledgeModal = () => {
        this.setState({knowledgeIsOpen: true});
    }
    /**
     * Closes the popup 
     */
    closeModal = () => {
        this.setState({isOpen: false});
    }
    /**
     * Contributes the user's data when clicked
     */
    contributeData = () => {
        this.setState({dataContributedPopup: true});
    }
    closeContributeData = () => {
        this.setState({dataContributedPopup: false});
    }

    // handlePlanningSelectChange = (event) => {
    //     this.setState({
    //         planningSelectValue: event.target.value
    //     });
    // }

    render () {
        const { classes } = this.props;
        return (
            <>
                {/* <Prompt
                    when={!this.state.resultsSaved}
                    message='You have not selected how you plan to attend this meeting, are you sure you want to leave?'
                /> */}
                <div className={classes.container} style={{display: "flex", flexDirection: "column"}}>
                    {/* Render header and best option */}
                    <Header />
                    <Stepper activeStep={5} nonLinear alternativeLabel className={classes.stepper}>
                        <Step>
                            <StepButton><Link to="/questions-intro">Introduction</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-demographics">Demographics</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-meeting_profile">Meeting Profile</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-mental_workload_ssa">Mental Workload & Shared Situational Awareness</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton><Link to="/questions-confidence_perf">Confidence in Performance</Link></StepButton>
                        </Step>
                        <Step>
                            <StepButton>Recommendation Results</StepButton>
                        </Step>
                        <Step>
                            <StepButton>Specific Follow-up Questions</StepButton>
                        </Step>
                        <Step>
                            <StepButton>General Follow-ups</StepButton>
                        </Step>
                    </Stepper>
                    {/* Render the datatable, and have onClick to open popups */}
                    <div className={classes.content_container} style={{margin: "30px"}} /*onClick={this.openModal}*/>
                        <ResultsTable setDegree={this.props.setDegree} classes={this.props.classes}/>
                    </div>
                    {/* generates the construct def popup */}
                    <Dialog open={this.state.isOpen} onClose={this.closeModal} maxWidth='md' fullWidth={true}>
                        <DialogTitle margin='10%' align='center' style={{fontFamily:'Open Sans, sans-serif'}}>{this.title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText margin='10%' align='center'>
                                {this.definition}
                            </DialogContentText>
                        </DialogContent>
                        <Button onClick={this.closeModal}>Close</Button>
                    </Dialog>
                    {/* <Link to={this.state.followupLink}><Button className={classes.submitButton} style={{float: 'right'}}>
                        Next
                    </Button></Link> */}
                </div>
                <br></br>
          </>
        );
    }
}

const mapStateToProps = (state) => ({
    quiz: state.quiz,
    answers: state.answers
});

const mapDispatchToProps = { setDegree };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Results));