import { createSlice } from '@reduxjs/toolkit'

/**
 * sets the initial slice states
 */
const initialState = {
    knowledge: "Face-to-Face",
    mental_workload: "Face-to-Face",

    // usability_eau: "Face-to-Face",
    // usability_te: "Face-to-Face",

    ssa: "Face-to-Face",
    immediate_perf: "Face-to-Face",
    future_perf: "Face-to-Face",
}

/**
 * allows us to change the slices
 */
export const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        setDegree: (state, action) => {
            // console.log(action); //used for testing
            state[action.payload.section] = action.payload.degree;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setDegree } = quizSlice.actions

export default quizSlice.reducer