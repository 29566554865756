import Header from './Header'
import { makeStyles, Button } from "@material-ui/core"
import React from 'react'
import { Link } from 'react-router-dom';

/**
 * sets the css styles for the different components
 */
const useStyles = makeStyles(() => ({
    quizButton: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 700,
      fontSize: "20px",
    //   textTransform: "lowercase",
      color: "#FFFFEE",
      backgroundColor: "#1E2124",
    },
    mainWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "40px",
        // textTransform: "lowercase",
        textAlign: "center",
        color: "#1E2124",
    },
    container: {
        width: "99%",
        height: 450,
        marginTop: 100,
        display: "fixed",
    },
    link: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "18px",
        // textTransform: "lowercase",
        color: "#1E2124",
        textAlign: "center",
    },
}));

/**
 * @returns the message + start quiz button
 */
function Quiz() {
    const { container, link, mainWords, quizButton } = useStyles();

    return(
        <>
            <Header />
            <div className={container}>
                <br></br><br></br><br></br><br></br><br></br>
                <h2 className={mainWords}>
                    Find your meeting format recommendation
                </h2>
                <br></br>
                <div style={{textAlign: "center"}}>
                    {/* <Link to="/questions-knowledge_mentalWorkload"><Button variant="contained" className={quizButton}> */}
                    <Link to="/questions-intro"><Button variant="contained" className={quizButton}>
                        Start Quiz
                    </Button></Link>
                </div>
                <br></br><br></br>
                <div style={{textAlign: "center"}}>
                    <Link to="/results" className={link}>Or Skip Directly to Our Tool</Link>
                </div>
            </div>
        </>
    );
}

export default Quiz;