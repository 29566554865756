export const createResponsesRecord = (
        quiz,
        answers,
        attendancePlan,
        attendanceRec
    ) => {
    var dateString = (new Date()).toLocaleString("en-US").replace(",", "");
    // var url = "database/records/tst_record_" + Date.parse(dateString).toString() + ".json";
    var url = "/results/records"
    var data = {
        created: dateString,
        quiz: quiz,
        answers: answers,
        attendancePlan: attendancePlan,
        attendanceRec: attendanceRec,
    };
    const options = { 
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }    
    
    fetch(url, options).then(response => {  
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...');
        }
    })
};