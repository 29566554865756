import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Dialog from "@material-ui/core/Dialog";
import { styled } from '@mui/material/styles';
import Checkbox from '@material-ui/core/Checkbox'
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import { makeStyles, Typography, Button, TableBody, TableRow, TableCell } from "@material-ui/core"
import DialogContentText from "@material-ui/core/DialogContentText";
import {Link} from 'react-router-dom';

import { setDegree } from '../../features/quizSlice';
import { setAnswer } from '../../features/answersSlice';

/**
 * constant that sets the styling for different types of text
 */
const useStyles = makeStyles(() => ({
    questionWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    answerWordsBold: {
        // fontFamily: "Open Sans, sans-serif",
        fontWeight: "bold",
        fontSize: "23px",
        color: "#1E2124",
    },

    divWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "23px",
        color: "#1E2124",
    },
    warningWords: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 500,
        fontSize: "20px",
        letterSpacing: 0,
        color: "#1E2124",
    },
    submitButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        fontSize: "25px",
        textTransform: "lowercase",
        color: "#FFFFEE",
        backgroundColor: "#1E2124",
      },
}));

/**
 * sets styling for the dividers
 */
const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  /**
   * sets the styling for the question boxes
   */
const themeStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      fontFamily: "Open Sans, sans-serif",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    label: {
        fontFamily: "Open Sans, sans-serif",
        fontSize: "60px",
    },
    plusSign: {
        color: "green",
        fontSize: "40px",
    },
    crossSign: {
        color: "red",
        fontSize: "40px",
    },
}));

var estimatedEmissionsHeader, estimatedEmissionsBody, howEstimatedEmissionsHeader, howEstimatedEmissionsBody;
function showFollowupResults(state, warningWords, answerWordsBold, isParticipantsValid, isDistanceValid) {
    estimatedEmissionsHeader = estimatedEmissionsBody = howEstimatedEmissionsHeader = howEstimatedEmissionsBody = "";
    var hasNext = false;

    if (isParticipantsValid && isDistanceValid) {
        var distance = parseFloat(state.q5);
        var participants = parseInt(state.q57);
        var flightEmissions = distance * participants * 0.24;
        var driveEmissions = distance * 0.89;
        hasNext = true;
        estimatedEmissionsHeader =  
            <p className={answerWordsBold}>
                Your estimated emissions for attending this meeting in person are:
            </p>;
        estimatedEmissionsBody =  
        <p className={warningWords}>
            {flightEmissions} pounds of Carbon Dioxide for {participants} participant(s)
            <br></br>
            OR
            <br></br>
            {driveEmissions} pounds of Carbon Dioxide per vehicle driving.
        </p>;
        howEstimatedEmissionsHeader = 
            <p className={answerWordsBold}>
                <b>How did we estimate emissions?</b>
            </p>;
        howEstimatedEmissionsBody = 
        <p className={warningWords}>
            Flying: Miles roundtrip &#215; participants &#215; .24* pounds CO<sub>2</sub>/mile
            <br></br>
            Driving: Miles roundtrip &#215; .89** pounds CO<sub>2</sub>/mile
            <br></br>
            <br></br>
            *actual carbon dioxide per mile per passenger varies by aircraft type, number of passengers, flight conditions, and cargo. .24 pounds of CO<sub>2</sub>/mile provided by <a href='https://blueskymodel.org/air-mile#:~:text=To%20the%20best%20of%20our,short%20tons%20of%20carbon%20dioxide.'>BlueSkyModel</a>
            <br></br>
            **actual carbon dioxide per mile varies by vehicle type, age, fuel, weight, and driving conditions. Calculation assumes gasoline or diesel engine. .89 pounds of CO<sub>2</sub>/mile provided by the <a href='https://www.epa.gov/greenvehicles/greenhouse-gas-emissions-typical-passenger-vehicle'>EPA</a>
        </p>;
    }
    return hasNext;
}

/**
 * @returns all the rendered questions and answers + dividers
 */
function F2FFollowupsQuestions() {
    //text styles call
    const { questionWords, answerWords, answerWordsBold, divWords, warningWords, submitButton } = useStyles();
    //questions style call
    const classes = themeStyles();
    //creates all the default values for the answers
    const answers = useSelector((s) => s.answers);
    var answerName = "f2f";
    var initialState = {
        q52: ("q52" in answers[answerName]) ? answers[answerName]["q52"] : null, // Are you the meeting organizer?
        q57: ("q57" in answers[answerName]) ? answers[answerName]["q57"] : null, // How many participants will be attending? If you are not the organizer and/or will be arranging travel for yourself only, enter 1.
        q5: ("q5" in answers[answerName]) ? answers[answerName]["q5"] : null, // What is the average distance in miles (roundtrip) participants will travel to attend the meeting?
    }
    // const [state, setState] = React.useState({
    //     q52: null, // Are you the meeting organizer?
    //     q57: null, // How many participants will be attending? If you are not the organizer and/or will be arranging travel for yourself only, enter 1.
    //     q5: null, // What is the average distance in miles (roundtrip) participants will travel to attend the meeting?
    // });
    const [state, setState] = React.useState(initialState);

    const checkParticipants = (participants) => {
        return participants !== null && !isNaN(participants) && participants >= 1;
    }
    const checkDistance = (distance) => {
        return distance !== null && !isNaN(distance) && distance >= 0.0;
    }

    const [hasNext, setHasNext] = React.useState(false);
    const [isParticipantsValid, setIsParticipantsValid] = React.useState(checkParticipants(state.q57));
    const [isDistanceValid, setiIsDistanceValid] = React.useState(checkDistance(state.q5));

    /**
     * updates knowledge and workload every time theres a state change
     */
    React.useEffect(() => {
        Object.keys(state).map((k, i) => (
            dispatch(setAnswer({section: answerName, questionId: k, answer: state[k]}))
        ));
        setHasNext(isParticipantsValid && isDistanceValid);
    });

    //handles question box toggle changes
    const handleRadioChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
        dispatch(setAnswer({section: "f2f", questionId: event.target.name, answer: event.target.value}));
        // console.log(event.target.name);
        // console.log(event.target.value);
    };

    // Validates number of participants value
    const validateParticipants = (event) => {
        var participants = parseInt(event.target.value);
        if (checkParticipants(participants)) {
            setIsParticipantsValid(true);
            setState({ ...state, ["q57"]: participants });
            dispatch(setAnswer({section: "f2f", questionId: "q57", answer: participants}));
        } else {
            setState({ ...state, ["q57"]: '' });
            setIsParticipantsValid(false);
        }
    };

    // Validates average distance value
    const validateDistance = (event) => {
        var distance = parseFloat(event.target.value);
        if (checkDistance(distance)) {
            setiIsDistanceValid(true);
            setState({ ...state, ["q5"]: distance });
            dispatch(setAnswer({section: "f2f", questionId: "q5", answer: distance}));
        } else {
            setState({ ...state, ["q5"]: '' });
            setiIsDistanceValid(false);
        }
    };

    const dispatch = useDispatch();

    return(
        <Root>
        <div>
            {/* F2F Follow-ups */}
            <div>
                <p className={warningWords}>
                    Based upon your answers, we recommend holding a <b>face-to-face</b> meeting. Answer the following questions to determine potential emissions.
                </p>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel className={questionWords} component="legend">Are you the meeting organizer?</FormLabel>
                    <br></br>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="q52" value={state.q52}>
                        <FormControlLabel
                            value="q52a1" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>No</Typography>}
                        />
                        <FormControlLabel
                            value="q52a2" control={<Radio onChange={handleRadioChange}/>}
                            label={<Typography className={answerWords}>Yes</Typography>}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl} name="participants">
                    <FormLabel className={questionWords} component="legend">How many participants will be attending? If you are not the organizer and/or will be arranging travel for yourself only, enter 1.</FormLabel>
                    <br></br>
                    <TextField aria-labelledby="demo-text-field-group-label" id="q57" value={state.q57} variant="standard" inputProps={{ maxLength: 6 }} onChange={validateParticipants} error={!isParticipantsValid} helperText={isParticipantsValid ? "" : "Number of participants must be a positive integer."}></TextField>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            <div className={classes.root}>
                <FormControl component="fieldset" className={classes.formControl} name="distance">
                    <FormLabel className={questionWords} component="legend">What is the average distance in miles (roundtrip) participants will travel to attend the meeting?</FormLabel>
                    <br></br>
                    <TextField aria-labelledby="demo-text-field-group-label" id="q5" value={state.q5} variant="standard" inputProps={{ maxLength: 6 }} onChange={validateDistance} error={!isDistanceValid} helperText={isDistanceValid ? "" : "Average distance must be a positive real number."}></TextField>
                </FormControl>
            </div>
            <Divider textAlign="right" className={divWords}></Divider>
            {showFollowupResults(state, warningWords, answerWordsBold, isParticipantsValid, isDistanceValid)}
            {estimatedEmissionsHeader}
            {estimatedEmissionsBody}
            {howEstimatedEmissionsHeader}
            {howEstimatedEmissionsBody}
            <Divider textAlign="right" className={divWords}></Divider>
            <br></br><br></br>
            {/* <Link to="/questions-confidence_perf"><Button className={submitButton}>
                Back
            </Button></Link> */}
            {
                (hasNext)
                ?
                <Link to={{pathname: "/questions-general_followups", state: {finalRecLink: "/questions-f2f_followups"}}}><Button className={submitButton} style={{float: 'right'}}>
                    Next
                </Button></Link>
                :
                ""
            }
        </div>
        </Root>
    );
}

export default F2FFollowupsQuestions;